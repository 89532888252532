import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Icon,
  Select,
  SimpleGrid,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Card from "components/card/Card.js";
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import NFT from "components/card/NFT";
import IconBox from "components/icons/IconBox";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { baseURL } from "constant/api";
import { CurrentMonth } from "constant/currentMonth";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdGroupWork,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
// import { Link } from "react-router-dom";
import { SelectProfile } from "redux/reducer";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("white", "#ff0052");
  const boxBg = useColorModeValue("#ff0052", "whiteAlpha.100");
  const loginData = localStorage.getItem("login");
  const Myprofile = JSON.parse(loginData);
  const [myKpi, setKpi] = React.useState(0);
  const [jobspecific2, setJobSpecific2] = React.useState(0);
  const ProfileId = JSON.parse(loginData);
  const [selectManager, setSelectManger] = React.useState(ProfileId.department);
  const [selectedManagerId, setSelectedManager] = React.useState("");
  const [support, setSupport] = useState([]);
  const [TeamData, setTeamData] = React.useState([]);
  const [departmentTable, setDepartment] = React.useState("");
  const [AllTeam, setAllTeam] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [loading_Chart, setLoading_Chart] = React.useState(false);
  const [Directors, setDirectors] = useState([]);
  const [TopMember, setTopMember] = useState([]);
  const [allTeamData, setAllTeamData] = useState([]);

  const [DesignNight, setDesign_night] = useState([]);
  const [Design, setDesign] = useState([]);
  const [development, setDevelopment] = useState([]);
  const [brand, setBrand] = useState([]);
  const [qualityAssurance, setQualityAssurance] = useState([]);
  const [ProjectCoordinator, setProjectCoordinator] = useState([]);
  const [HumanResource, setHR] = useState([]);
  const [Managers, setManagers] = useState([]);
  const [AssistantIT, setAssistantIT] = useState([]);
  const [Finance, setFinance] = useState([]);
  const [selectMonth, setMonth] = useState(CurrentMonth);
  const [director, setDirector] = useState([]);
  const [Sales,setSales] = useState([])
  const [monthKpi,setMonthKpi] = useState(CurrentMonth)
  const [AllMonthKpi,setAllMonthKpi] = useState([])
  const [seo,setSeo] = useState([])

  React.useEffect(() => {
    Myprofile.department == "Director" ||
    Myprofile.department == "human resources" ||
    Myprofile.superAdmin == true
      ? fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            // compliance;
            if(Myprofile.role == 'Bussiness Unit Head'){
              var newArray = response.filter(function (el) {
                return el.compliance == false && (el.department == 'brand designer' || el.department == 'brand')
              });
              Myprofile.department == "human resources" ? setData(response): setData(newArray);
            }
            else{

              var newArray = response.filter(function (el) {
                return el.compliance == false && el.department != 'brand designer' && el.department != 'brand'
              });
              Myprofile.department == "human resources" ? setData(response): setData(newArray);
            }
            
          })
          .catch((err) => {
            console.log(err);
          })
      : fetch(
          `${baseURL}api/auth/team?department=${Myprofile.department}&manager=false`
        )
          .then((resp) => resp.json())
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);
  useEffect(() => {
    fetch(`${baseURL}api/kpi/par_kpi?id=${Myprofile._id}`)
      .then((resp) => resp.json())
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });

    fetch(
      `${baseURL}api/kpi/par_kpi_mon?id=${Myprofile._id}&month=${months[CurrentMonth == 'january' ? 11: new Date().getMonth() - 1]}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setKpi(
          (response[0]?.communication +
            response[0]?.behaviour +
            response[0]?.ownership +
            response[0]?.teamwork +
            response[0]?.qualitywork +
            response[0]?.timelydeliver +
            response[0]?.overall +
            response[0]?.goals) /
            8
        );
      })
      .catch((err) => console.log(err));
    {
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources"
        ? fetch(`${baseURL}api/auth/managers`)
            .then((resp) => resp.json())
            .then((response) => setAllTeam(response.length))
            .catch((err) => console.log(err))
        : fetch(
            `${baseURL}api/auth/team?department=${Myprofile.department}&manager=false`
          )
            .then((resp) => resp.json())
            .then((response) => setAllTeam(response.length))
            .catch((err) => console.log(err));
    }
  }, []);
  useEffect(() => {
    //For Team
    fetch(
      `${baseURL}api/jobspecific/getquarterlyperfomance?id=${Myprofile._id}&month=${CurrentMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        if (response == []) {
          // setJobSpecific(0);
        } else {
          setJobSpecific2(response?.JobSpecific);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const textColor = useColorModeValue("#1A1A1C", "white");
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  useEffect(() => {
    fetch(
      `${baseURL}api/kpi/top_team_member?month1=${
        months[CurrentMonth == 'january'? 9 : CurrentMonth == 'february' ? 10: new Date().getMonth() - 3]
      }&month2=${months[CurrentMonth == 'january'? 10 : CurrentMonth == 'february' ? 11: new Date().getMonth() - 2]}&month3=${months[CurrentMonth == 'january'? 11 : CurrentMonth == 'february' ? new Date().getMonth() - 1: new Date().getMonth() - 1]}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        if(CurrentMonth == 'january' || CurrentMonth == 'april' || CurrentMonth == 'july' || CurrentMonth == 'october') {
          setTopMember(response)
        } 
        else{

        }
      })
      .catch((err) => {
        console.log(err);
      });

  //AllKpi
  fetch(
    `${baseURL}api/kpi/allkpi_month?month=${monthKpi}`
  )
    .then((resp) => resp.json())
    .then((response) => {
      setAllMonthKpi(response);
    })
    .catch((err) => {
      console.log(err);
    });

    //DesignNight
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=design_night`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setDesign_night(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Design
    fetch(`${baseURL}api/kpi/top_member?month=${selectMonth}&department=design`)
      .then((resp) => resp.json())
      .then((response) => {
        setDesign(response);
      })
      .catch((err) => {
        console.log(err);
      });
       //Sales
    fetch(`${baseURL}api/kpi/top_member?month=${selectMonth}&department=sales`)
    .then((resp) => resp.json())
    .then((response) => {
      setSales(response);
    })
    .catch((err) => {
      console.log(err);
    });
    //Director
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=Director`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setDirector(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Development
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=development`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setDevelopment(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Brand
    fetch(`${baseURL}api/kpi/top_member?month=${selectMonth}&department=brand`)
      .then((resp) => resp.json())
      .then((response) => {
        setBrand(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Quality Assurance
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=quality assurance`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setQualityAssurance(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Project Coordinator
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=project coordination`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setProjectCoordinator(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //human resources
    fetch(
      `${baseURL}api/kpi/allkpi_month?month=${selectMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        var newArray = response.filter(function (el) {
          
          return el.teamId == '6347026485d8e33605a9df27'
        });
        setHR(newArray);
      })
      .catch((err) => {
        console.log(err);
      });
  
    // fetch(
    //   `${baseURL}api/kpi/top_member?month=${selectMonth}&department=human resources`
    // )
    //   .then((resp) => resp.json())
    //   .then((response) => {
       
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    //"assistant it"
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=assistant it`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setAssistantIT(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //Finance"
    fetch(
      `${baseURL}api/kpi/top_member?month=${selectMonth}&department=finance`
    )
      .then((resp) => resp.json())
      .then((response) => {
        console.log("Respomse", response);
        setFinance(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //For Managers
    fetch(`${baseURL}api/kpi/top_member_managers?month=${selectMonth}`)
      .then((resp) => resp.json())
      .then((response) => {
        setManagers(response);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`${baseURL}api/auth/allteam`)
      .then((resp) => resp.json())
      .then((response) => setAllTeamData(response))
      .catch((err) => console.log(err));

    if (
      Myprofile.department == "Director" ||
      Myprofile.superAdmin == true ||
      Myprofile.department == "ceo" ||
      Myprofile.department == "human resources"
    ) {
      fetch(`${baseURL}api/auth/team?department=${selectManager}&manager=false`)
        .then((resp) => resp.json())
        .then((response) => {
          setTeamData(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }else if( Myprofile.department == "sirjawad"){
      fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            var newArray = response.filter(function (el) {
              return el.compliance == true 
            });
            setTeamData(newArray);
          })
          .catch((err) => {
            console.log(err);
          })
    } else {
      fetch(`${baseURL}api/auth/team?department=${selectManager}&manager=false`)
        .then((resp) => resp.json())
        .then((response) => {
          setData(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //For Support Team

    fetch(
      `${baseURL}api/auth/team?department=${
        selectManager == "development"
          ? "quality assurance"
          : selectManager == "brand"
          ? "project coordination"
          : null
      }&manager=false`
    )
      .then((resp) => resp.json())
      .then((response) => {
        setSupport(response);
      })
      .catch((err) => {
        console.log(err);
      });
      fetch(
        `${baseURL}api/auth/team?department=${
          selectManager == "development"
            ? "quality assurance"
            : selectManager == "brand"
            ? "project coordination"
            : null
        }&manager=false`
      )
        .then((resp) => resp.json())
        .then((response) => {
          setSupport(response);
        })
        .catch((err) => {
          console.log(err);
        });

        fetch(
          `${baseURL}api/auth/team?department=${
          selectManager == "brand"
              ? "seo"
              : null
          }&manager=false`
        )
          .then((resp) => resp.json())
          .then((response) => {
            setSeo(response)
            
          })
          .catch((err) => {
            console.log(err);
          });
    //End Support Team
    fetch(`${baseURL}api/auth/team?department=Director&manager=false`)
      .then((resp) => resp.json())
      .then((response) => {
        setDirectors(response);
      })
      .catch((err) => {
        console.log(err);
      });
    //For Team
  }, [selectManager, selectMonth,monthKpi]);

  const dispatch = useDispatch();
  const SelectProfil = useSelector((store) => store.counter.selectProfile);
  const Support_and_Coordination = [
    "quality assurance",
    "project coordination",
    "content writter",
  ];
  const developement = ["development", "quality assurance"];
  const design = ["design"];
  const designNight = ["design_night"];
  const operation = ["operation"];
  const sales = ["sales"];
  const Brand = ["brand", "project coordination"];
  const FinanceandSales = ["finance"];
  const IT = ["assistant it"];
  const HR = ["human resources"];
  const send_Api_to_Team = () => {
    fetch(
      `${baseURL}api/kpi/email_sent_All_team?managerId=${selectedManagerId}&month=${months[CurrentMonth == 'january' ?11: new Date().getMonth() - 1]}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .then((response) => {
        if (TeamData.length <= response.sortedKpi.length) {
          alert("Email Send", "response");
        } else {
          alert("The score is pending from the manager side");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {Myprofile.manager == true && (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="28px" h="28px" as={MdGroupWork} color={brandColor} />
                }
              />
            }
            name="My Team"
            value={Myprofile.department == 'brand' ? AllTeam+4:AllTeam}
          />
        )}
        
        {Myprofile.department == 'sirjawad' || Myprofile.department == 'ceo' ? null: <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name="Current KPI"
          value={myKpi.toFixed(2) }
        />}
        {Myprofile.email == 'waqas@fleekbiz.com' ||
         Myprofile.email == 'anas@fleekbiz.com' || 
         Myprofile.email == 'talat@fleekbiz.com' ||
          Myprofile.email == 'jay@fleekbiz.com' ||
          Myprofile.email == 'sakina@fleekbiz.com' ? <a href="https://feedback.fleekbiz.com/officeboy/" target="_blank">
          <Button
           variant="darkBrand"
           color="white"
           fontSize="sm"
           fontWeight="500"
           bg={"#070707"}
           h="85px"
           marginRight={"10px"}
         >
           Fill Office Boy KPI.
           
         </Button>
         </a>
         :null }
        
        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name="Job Specific"
          value={jobspecific2}
        /> */}
      </SimpleGrid>
      {Myprofile.superAdmin == true ||
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources" ? (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
          gap="20px"
          mb="20px"
        >
          {TopMember?.manager?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return <>{itemer._id == item.id ? itemer.name : null}</>;
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>{itemer._id == item.id ? itemer.role : null}</>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Quarterly KPI ={" "}
                            {Number(item.kpi).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.id ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
{/* 
          Exective */}

        {TopMember?.executive?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return <>{itemer._id == item.id ? itemer.name : null}</>;
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>{itemer._id == item.id ? itemer.role : null}</>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Quarterly KPI ={" "}
                            {Number(item.kpi).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.id ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </SimpleGrid>
      ) : null}

{/* 
Monthly Award */}
      
      {Myprofile.superAdmin == true ||
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources" ? (
        <Card
          align="center"
          direction="column"
          // w="30%"
          style={{ marginBottom: "5px" }}
        >
          <Text
            color={textColor}
            fontSize="2xl"
            // ms="24px"
            fontWeight="700"
            textAlign={"left"}
            marginBottom={"10px"}
          >
            Select month for performance
          </Text>
          <Select onChange={(event) => setMonth(event.currentTarget.value)}>
            <option value={CurrentMonth}>{String(CurrentMonth).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}</option>
           
            <option value={CurrentMonth == 'january' ? months[11] : months[new Date().getMonth() - 1]}>
              {String(CurrentMonth == 'january' ? months[11] : months[new Date().getMonth() - 1]).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}
            </option>
            <option value={CurrentMonth == 'january' || CurrentMonth == 'february'  ? months[CurrentMonth == 'february' ?  11: 10] : months[new Date().getMonth() - 2]}>
              {String(CurrentMonth == 'january' || CurrentMonth == 'february'  ? months[CurrentMonth == 'february' ?  11: 10] : months[new Date().getMonth() - 2]).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}
            </option>

            
          
          </Select>
        </Card>
      ) : null}

      {/* Design Night  */}

      {Myprofile.superAdmin == true ||
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources" ? (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
          gap="20px"
          mb="20px"
        >
          {(DesignNight[0]?.communication +
            DesignNight[0]?.behaviour +
            DesignNight[0]?.ownership +
            DesignNight[0]?.teamwork +
            DesignNight[0]?.qualitywork +
            DesignNight[0]?.timelydeliver +
            DesignNight[0]?.goals +
            DesignNight[0]?.overall) >
            (Design[0]?.communication +
              Design[0]?.behaviour +
              Design[0]?.ownership +
              Design[0]?.teamwork +
              Design[0]?.qualitywork +
              Design[0]?.timelydeliver +
              Design[0]?.goals +
              Design[0]?.overall)
            ? (DesignNight[0]?.communication +
                DesignNight[0]?.behaviour +
                DesignNight[0]?.ownership +
                DesignNight[0]?.teamwork +
                DesignNight[0]?.qualitywork +
                DesignNight[0]?.timelydeliver +
                DesignNight[0]?.goals +
                DesignNight[0]?.overall) >
              (brand[0]?.communication +
                brand[0]?.behaviour +
                brand[0]?.ownership +
                brand[0]?.teamwork +
                brand[0]?.qualitywork +
                brand[0]?.timelydeliver +
                brand[0]?.goals +
                brand[0]?.overall)
              ? DesignNight?.map((item, index) => {
                  return (
                    <div className="container">
                      <div className="card">
                        <div className="lines"></div>
                        <div className="imageBox">
                          <img
                            src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                            alt="avatar"
                          />
                        </div>
                        <div className="content">
                          <div className="details">
                            <h2>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.name
                                      : null}
                                  </>
                                );
                              })}
                              <br />{" "}
                              <span>
                                {allTeamData.map((itemer) => {
                                  return (
                                    <>
                                      {itemer._id == item.teamId
                                        ? itemer.role
                                        : null}
                                    </>
                                  );
                                })}
                              </span>
                            </h2>
                            <div className="info">
                              <Text
                                color={textColor}
                                fontSize="2xl"
                                // ms="24px"
                                fontWeight="700"
                                textAlign={"center"}
                                alignSelf={"center"}
                              >
                                <h1>
                                  Current KPI ={" "}
                                  {Number(
                                    (item.communication +
                                      item.behaviour +
                                      item.ownership +
                                      item.teamwork +
                                      item.qualitywork +
                                      item.timelydeliver +
                                      item.goals +
                                      item.overall) /
                                      8
                                  ).toFixed(2)}
                                </h1>
                              </Text>
                              <Text
                                color={textColor}
                                fontSize="2xl"
                                // ms="24px"
                                fontWeight="700"
                              >
                                {""}
                                {/* <h1>KPI</h1> */}
                              </Text>
                            </div>
                            <div className="actions">
                              <Link
                                href={"#/admin/singleprofile"}
                                mt={{
                                  base: "0px",
                                  md: "10px",
                                  lg: "0px",
                                  xl: "10px",
                                }}
                              >
                                {allTeamData.map((itemer) => {
                                  return (
                                    <>
                                      {itemer._id == item.teamId ? (
                                        <Button
                                          variant="darkBrand"
                                          color="white"
                                          fontSize="sm"
                                          fontWeight="500"
                                          bg={"#070707"}
                                          marginRight={"10px"}
                                          onClick={async () => {
                                            dispatch(SelectProfile(itemer));
                                            const items =
                                              JSON.stringify(itemer);
                                            await localStorage.setItem(
                                              "SelectProfile",
                                              items
                                            );
                                          }}
                                        >
                                          View
                                        </Button>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : brand?.map((item, index) => {
                  return (
                    <div className="container">
                      <div className="card">
                        <div className="lines"></div>
                        <div className="imageBox">
                          <img
                            src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                            alt="avatar"
                          />
                        </div>
                        <div className="content">
                          <div className="details">
                            <h2>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.name
                                      : null}
                                  </>
                                );
                              })}
                              <br />{" "}
                              <span>
                                {allTeamData.map((itemer) => {
                                  return (
                                    <>
                                      {itemer._id == item.teamId
                                        ? itemer.role
                                        : null}
                                    </>
                                  );
                                })}
                              </span>
                            </h2>
                            <div className="info">
                              <Text
                                color={textColor}
                                fontSize="2xl"
                                // ms="24px"
                                fontWeight="700"
                                textAlign={"center"}
                                alignSelf={"center"}
                              >
                                <h1>
                                  Current KPI ={" "}
                                  {Number(
                                    (item.communication +
                                      item.behaviour +
                                      item.ownership +
                                      item.teamwork +
                                      item.qualitywork +
                                      item.timelydeliver +
                                      item.goals +
                                      item.overall) /
                                      8
                                  ).toFixed(2)}
                                </h1>
                              </Text>
                              <Text
                                color={textColor}
                                fontSize="2xl"
                                // ms="24px"
                                fontWeight="700"
                              >
                                {""}
                                {/* <h1>KPI</h1> */}
                              </Text>
                            </div>
                            <div className="actions">
                              <Link
                                href={"#/admin/singleprofile"}
                                mt={{
                                  base: "0px",
                                  md: "10px",
                                  lg: "0px",
                                  xl: "10px",
                                }}
                              >
                                {allTeamData.map((itemer) => {
                                  return (
                                    <>
                                      {itemer._id == item.teamId ? (
                                        <Button
                                          variant="darkBrand"
                                          color="white"
                                          fontSize="sm"
                                          fontWeight="500"
                                          bg={"#070707"}
                                          marginRight={"10px"}
                                          onClick={async () => {
                                            dispatch(SelectProfile(itemer));
                                            const items =
                                              JSON.stringify(itemer);
                                            await localStorage.setItem(
                                              "SelectProfile",
                                              items
                                            );
                                          }}
                                        >
                                          View
                                        </Button>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            : (Design[0]?.communication +
                Design[0]?.behaviour +
                Design[0]?.ownership +
                Design[0]?.teamwork +
                Design[0]?.qualitywork +
                Design[0]?.timelydeliver +
                Design[0]?.goals +
                Design[0]?.overall) >
              (brand[0]?.communication +
                brand[0]?.behaviour +
                brand[0]?.ownership +
                brand[0]?.teamwork +
                brand[0]?.qualitywork +
                brand[0]?.timelydeliver +
                brand[0]?.goals +
                brand[0]?.overall)
            ? Design?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : brand?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

        
          {/* Development
           */}
          {development?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return (
                            <>
                              {itemer._id == item.teamId ? itemer.name : null}
                            </>
                          );
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.role : null}
                              </>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Current KPI ={" "}
                            {Number(
                              (item.communication +
                                item.behaviour +
                                item.ownership +
                                item.teamwork +
                                item.qualitywork +
                                item.timelydeliver +
                                item.goals +
                                item.overall) /
                                8
                            ).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

           {/*Sales
           */}
          {Sales?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return (
                            <>
                              {itemer._id == item.teamId ? itemer.name : null}
                            </>
                          );
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.role : null}
                              </>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Current KPI ={" "}
                            {Number(
                              (item.communication +
                                item.behaviour +
                                item.ownership +
                                item.teamwork +
                                item.qualitywork +
                                item.timelydeliver +
                                item.goals +
                                item.overall) /
                                8
                            ).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* {
          Managers and Director} */}
          {Managers[0]?.communication +
            Managers[0]?.behaviour +
            Managers[0]?.ownership +
            Managers[0]?.teamwork +
            Managers[0]?.qualitywork +
            Managers[0]?.timelydeliver +
            Managers[0]?.goals +
            Managers[0]?.overall ==
          director[0]?.communication +
            director[0]?.behaviour +
            director[0]?.ownership +
            director[0]?.teamwork +
            director[0]?.qualitywork +
            director[0]?.timelydeliver +
            director[0]?.goals +
            director[0]?.overall ? (
            <>
              {Managers?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {Directors?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
          {/*    Managers or Director */}

          {Managers[0]?.communication +
            Managers[0]?.behaviour +
            Managers[0]?.ownership +
            Managers[0]?.teamwork +
            Managers[0]?.qualitywork +
            Managers[0]?.timelydeliver +
            Managers[0]?.goals +
            Managers[0]?.overall >
          director[0]?.communication +
            director[0]?.behaviour +
            director[0]?.ownership +
            director[0]?.teamwork +
            director[0]?.qualitywork +
            director[0]?.timelydeliver +
            director[0]?.goals +
            director[0]?.overall
            ? Managers?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : director?.map((item, index) => {
                return (
                  <div className="container">
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imageBox">
                        <img
                          src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId
                                    ? itemer.name
                                    : null}
                                </>
                              );
                            })}
                            <br />{" "}
                            <span>
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId
                                      ? itemer.role
                                      : null}
                                  </>
                                );
                              })}
                            </span>
                          </h2>
                          <div className="info">
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                              textAlign={"center"}
                              alignSelf={"center"}
                            >
                              <h1>
                                Current KPI ={" "}
                                {Number(
                                  (item.communication +
                                    item.behaviour +
                                    item.ownership +
                                    item.teamwork +
                                    item.qualitywork +
                                    item.timelydeliver +
                                    item.goals +
                                    item.overall) /
                                    8
                                ).toFixed(2)}
                              </h1>
                            </Text>
                            <Text
                              color={textColor}
                              fontSize="2xl"
                              // ms="24px"
                              fontWeight="700"
                            >
                              {""}
                              {/* <h1>KPI</h1> */}
                            </Text>
                          </div>
                          <div className="actions">
                            <Link
                              href={"#/admin/singleprofile"}
                              mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                              }}
                            >
                              {allTeamData.map((itemer) => {
                                return (
                                  <>
                                    {itemer._id == item.teamId ? (
                                      <Button
                                        variant="darkBrand"
                                        color="white"
                                        fontSize="sm"
                                        fontWeight="500"
                                        bg={"#070707"}
                                        marginRight={"10px"}
                                        onClick={async () => {
                                          dispatch(SelectProfile(itemer));
                                          const items = JSON.stringify(itemer);
                                          await localStorage.setItem(
                                            "SelectProfile",
                                            items
                                          );
                                        }}
                                      >
                                        View
                                      </Button>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          {/* Managers
           */}

          {/* QualityAssurance? */}
          {(qualityAssurance[0]?.communication +
            qualityAssurance[0]?.behaviour +
            qualityAssurance[0]?.ownership +
            qualityAssurance[0]?.teamwork +
            qualityAssurance[0]?.qualitywork +
            qualityAssurance[0]?.timelydeliver +
            qualityAssurance[0]?.goals +
            qualityAssurance[0]?.overall) > 
            (ProjectCoordinator[0]?.communication +
            ProjectCoordinator[0]?.behaviour +
            ProjectCoordinator[0]?.ownership +
            ProjectCoordinator[0]?.teamwork +
            ProjectCoordinator[0]?.qualitywork +
            ProjectCoordinator[0]?.timelydeliver +
            ProjectCoordinator[0]?.goals +
            ProjectCoordinator[0]?.overall) ? qualityAssurance?.map((item, index) => {
              
              return (
                <div className="container">
                  <div className="card">
                    <div className="lines"></div>
                    <div className="imageBox">
                      <img
                        src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="content">
                      <div className="details">
                        <h2>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.name : null}
                              </>
                            );
                          })}
                          <br />{" "}
                          <span>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId ? itemer.role : null}
                                </>
                              );
                            })}
                          </span>
                        </h2>
                        <div className="info">
                          <Text
                            color={textColor}
                            fontSize="2xl"
                            // ms="24px"
                            fontWeight="700"
                            textAlign={"center"}
                            alignSelf={"center"}
                          >
                            <h1>
                              Current KPI ={" "}
                              {Number(
                                (item.communication +
                                  item.behaviour +
                                  item.ownership +
                                  item.teamwork +
                                  item.qualitywork +
                                  item.timelydeliver +
                                  item.goals +
                                  item.overall) /
                                  8
                              ).toFixed(2)}
                            </h1>
                          </Text>
                          <Text
                            color={textColor}
                            fontSize="2xl"
                            // ms="24px"
                            fontWeight="700"
                          >
                            {""}
                            {/* <h1>KPI</h1> */}
                          </Text>
                        </div>
                        <div className="actions">
                          <Link
                            href={"#/admin/singleprofile"}
                            mt={{
                              base: "0px",
                              md: "10px",
                              lg: "0px",
                              xl: "10px",
                            }}
                          >
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId ? (
                                    <Button
                                      variant="darkBrand"
                                      color="white"
                                      fontSize="sm"
                                      fontWeight="500"
                                      bg={"#070707"}
                                      marginRight={"10px"}
                                      onClick={async () => {
                                        dispatch(SelectProfile(itemer));
                                        const items = JSON.stringify(itemer);
                                        await localStorage.setItem(
                                          "SelectProfile",
                                          items
                                        );
                                      }}
                                    >
                                      View
                                    </Button>
                                  ) : null}
                                </>
                              );
                            })}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) :  ProjectCoordinator?.map((item, index) => {
              return (
                <div className="container">
                  <div className="card">
                    <div className="lines"></div>
                    <div className="imageBox">
                      <img
                        src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="content">
                      <div className="details">
                        <h2>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.name : null}
                              </>
                            );
                          })}
                          <br />{" "}
                          <span>
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId ? itemer.role : null}
                                </>
                              );
                            })}
                          </span>
                        </h2>
                        <div className="info">
                          <Text
                            color={textColor}
                            fontSize="2xl"
                            // ms="24px"
                            fontWeight="700"
                            textAlign={"center"}
                            alignSelf={"center"}
                          >
                            <h1>
                              Current KPI ={" "}
                              {Number(
                                (item.communication +
                                  item.behaviour +
                                  item.ownership +
                                  item.teamwork +
                                  item.qualitywork +
                                  item.timelydeliver +
                                  item.goals +
                                  item.overall) /
                                  8
                              ).toFixed(2)}
                            </h1>
                          </Text>
                          <Text
                            color={textColor}
                            fontSize="2xl"
                            // ms="24px"
                            fontWeight="700"
                          >
                            {""}
                            {/* <h1>KPI</h1> */}
                          </Text>
                        </div>
                        <div className="actions">
                          <Link
                            href={"#/admin/singleprofile"}
                            mt={{
                              base: "0px",
                              md: "10px",
                              lg: "0px",
                              xl: "10px",
                            }}
                          >
                            {allTeamData.map((itemer) => {
                              return (
                                <>
                                  {itemer._id == item.teamId ? (
                                    <Button
                                      variant="darkBrand"
                                      color="white"
                                      fontSize="sm"
                                      fontWeight="500"
                                      bg={"#070707"}
                                      marginRight={"10px"}
                                      onClick={async () => {
                                        dispatch(SelectProfile(itemer));
                                        const items = JSON.stringify(itemer);
                                        await localStorage.setItem(
                                          "SelectProfile",
                                          items
                                        );
                                      }}
                                    >
                                      View
                                    </Button>
                                  ) : null}
                                </>
                              );
                            })}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* 
ProjectCoordinator */}
          {/* 
          HR */}
          {HumanResource?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return (
                            <>
                              {itemer._id == item.teamId ? itemer.name : null}
                            </>
                          );
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.role : null}
                              </>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Current KPI ={" "}
                            {Number(
                              (item.communication +
                                item.behaviour +
                                item.ownership +
                                item.teamwork +
                                item.qualitywork +
                                item.timelydeliver +
                                item.goals +
                                item.overall) /
                                8
                            ).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* ITAssi */}

          {AssistantIT?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return (
                            <>
                              {itemer._id == item.teamId ? itemer.name : null}
                            </>
                          );
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.role : null}
                              </>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Current KPI ={" "}
                            {Number(
                              (item.communication +
                                item.behaviour +
                                item.ownership +
                                item.teamwork +
                                item.qualitywork +
                                item.timelydeliver +
                                item.goals +
                                item.overall) /
                                8
                            ).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* 
          FInance? */}
          {Finance?.map((item, index) => {
            return (
              <div className="container">
                <div className="card">
                  <div className="lines"></div>
                  <div className="imageBox">
                    <img
                      src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="content">
                    <div className="details">
                      <h2>
                        {allTeamData.map((itemer) => {
                          return (
                            <>
                              {itemer._id == item.teamId ? itemer.name : null}
                            </>
                          );
                        })}
                        <br />{" "}
                        <span>
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? itemer.role : null}
                              </>
                            );
                          })}
                        </span>
                      </h2>
                      <div className="info">
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <h1>
                            Current KPI ={" "}
                            {Number(
                              (item.communication +
                                item.behaviour +
                                item.ownership +
                                item.teamwork +
                                item.qualitywork +
                                item.timelydeliver +
                                item.goals +
                                item.overall) /
                                8
                            ).toFixed(2)}
                          </h1>
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="2xl"
                          // ms="24px"
                          fontWeight="700"
                        >
                          {""}
                          {/* <h1>KPI</h1> */}
                        </Text>
                      </div>
                      <div className="actions">
                        <Link
                          href={"#/admin/singleprofile"}
                          mt={{
                            base: "0px",
                            md: "10px",
                            lg: "0px",
                            xl: "10px",
                          }}
                        >
                          {allTeamData.map((itemer) => {
                            return (
                              <>
                                {itemer._id == item.teamId ? (
                                  <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    bg={"#070707"}
                                    marginRight={"10px"}
                                    onClick={async () => {
                                      dispatch(SelectProfile(itemer));
                                      const items = JSON.stringify(itemer);
                                      await localStorage.setItem(
                                        "SelectProfile",
                                        items
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : null}
                              </>
                            );
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </SimpleGrid>
      ) : null}

      {/* Design */}

      {Myprofile.manager == true  && Myprofile.department != "Director" && (
        <>
          {Myprofile.department == "ceo" || Myprofile.department == 'sirjawad' ? null : (
            <SimpleGrid gap="20px" mb="40px">
              {/* <DailyTraffic /> */}
            </SimpleGrid>
          )}
        </>
      )}
      {loading_Chart == true ? (
        <LoadingSpinner />
      ) : (
        <>
          {Myprofile.department == "ceo" || Myprofile.department == 'sirjawad' ? null : (
            <SimpleGrid>
              <WeeklyRevenue />
            </SimpleGrid>
          )}
        </>
      )}
      <SimpleGrid
        columns={{ base: 1, md: 1, xl: 2 }}
        marginBottom={"-75px"}
        marginTop={"100px"}
      >
        {Myprofile.manager == true &&
          Myprofile.department != "human resources" && (
            <Select
              onChange={(event) => setSelectManger(event.currentTarget.value)}
            >
              {ProfileId.department == "development" &&
              ProfileId.manager == true
                ? developement.map((item) => {
                    return (
                      <option value={item}>
                        {item == "quality assurance"
                          ? "Support and Coordination"
                          : item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "design_night" &&
                  ProfileId.manager == true
                ? designNight.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "design" && ProfileId.manager == true
                ? design.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "sales" && ProfileId.manager == true
                ? sales.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "assistant it" &&
                  ProfileId.manager == true
                ? IT.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "brand" && ProfileId.manager == true
                ? Brand.map((item) => {
                    return (
                      <option value={item}>
                        {item == "project coordination"
                          ? "Support and Coordination"
                          : item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "human resources" &&
                  ProfileId.manager == true
                ? HR.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : ProfileId.department == "finance" && ProfileId.manager == true
                ? FinanceandSales.map((item) => {
                    return (
                      <option value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })
                : null}
            </Select>
          )}
      </SimpleGrid>
      {Myprofile.manager == true || Myprofile.superAdmin == true ||
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources" ||
      Myprofile.department == "sirjawad" ? (
        <Card
          align="center"
          direction="column"
          // w="30%"
          style={{ marginBottom: "-5%" }}
        >
          <Text
            color={textColor}
            fontSize="2xl"
            // ms="24px"
            fontWeight="700"
            textAlign={"left"}
            marginBottom={"10px"}
          >
            Select month for KPI
          </Text>
          <Select onChange={(event) => setMonthKpi(event.currentTarget.value)}>
         

            <option value={CurrentMonth}>{String(CurrentMonth).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}</option>
           
            <option value={CurrentMonth == 'january' ? months[11] : months[new Date().getMonth() - 1]}>
              {String(CurrentMonth == 'january' ? months[11] : months[new Date().getMonth() - 1]).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}
            </option>
            <option value={CurrentMonth == 'january' || CurrentMonth == 'february'  ? months[CurrentMonth == 'february' ?  11: 10] : months[new Date().getMonth() - 2]}>
              {String(CurrentMonth == 'january' || CurrentMonth == 'february'  ? months[CurrentMonth == 'february' ?  11: 10] : months[new Date().getMonth() - 2]).toLowerCase()
                    .replace(/\w/, firstLetter => firstLetter.toUpperCase())}
            </option>
          </Select>
        </Card>
      ) : null}
      {Myprofile.department == "ceo"  || Myprofile.department == "human resources" || Myprofile.department == "sirjawad"? (
        <Grid
          // mb="20px"
          // gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
          // gap={{ base: "40px", xl: "10px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            <Flex direction="column">
              <Flex
                mt="100px"
                mb="20px"
                justifyContent="space-between"
                direction={{ base: "column", md: "row" }}
                align={{ base: "start", md: "center" }}
              >
                <Text
                  color={textColor}
                  fontSize="2xl"
                  ms="24px"
                  fontWeight="700"
                >
                 Director
                </Text>
              </Flex>
              <SimpleGrid minChildWidth="350px" spacing="20px">
                {Directors.map((item) => {
                 const find = AllMonthKpi.find((i) => {
                  return i.teamId == item._id
                 })
                  return (
                    <div>
                      <Box
                        borderWidth={4}
                        borderColor={
                          (item.department == selectManager &&
                            (Myprofile.department == "Director" ||
                              Myprofile.superAdmin == true)) ||
                          Myprofile.department == "human resources"
                            ? "gray.50"
                            : "darkBrand"
                        }
                        w="350px"
                        // p={1}
                        color="white"
                        borderRadius={"25px"}
                      >
                        <NFT
                          name={item.name}
                          author={item.role}
                          image={item.profilePic}
                          currentbid={Number(
                            (find?.communication +
                              find?.behaviour +
                              find?.ownership +
                              find?.teamwork +
                              find?.qualitywork +
                              find?.timelydeliver +
                              find?.goals +
                              find?.overall) /
                              8
                          ).toFixed(2) || 0}
                          download="#"
                          itemAll={item}
                        />
                      </Box>
                    </div>
                  );
                })}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Grid>
      ) : null}

      {Myprofile.manager == true ||
      Myprofile.superAdmin == true ||
      Myprofile.department == "Director" ||
      Myprofile.department == "human resources"
       ? (
        <Grid
          mb="20px"
          // gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
          gap={{ base: "40px", xl: "10px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            <Flex direction="column">
              <Flex
                mt="100px"
                mb="20px"
                justifyContent="space-between"
                direction={{ base: "column", md: "row" }}
                align={{ base: "start", md: "center" }}
              >
                <Text
                  color={textColor}
                  fontSize="2xl"
                  // ms="20px"
                  fontWeight="700"
                  bgGradient="linear(to-l, #ff0052,#FFF)"
                  bgClip="text"
                >
                  {"All TEAM"}
                </Text>
                {/* {Myprofile.department == "human resources" ? (
                  <Button
                    variant="darkBrand"
                    color="white"
                    fontSize="sm"
                    fontWeight="500"
                    bg={"#ff0052"}
                    borderRadius="70px"
                  >
                    Send Kpi to all team
                  </Button>
                ) : null} */}
              </Flex>
              <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
                {data.map((item) => {
                  const find = AllMonthKpi.find((i) => {
                   return i.teamId == item._id
                  })
                  return (
                    <Box
                      cursor={"pointer"}
                      onClick={() => {
                        setSelectManger(item.department);
                        setSelectedManager(item._id);
                      }}
                      borderWidth={2}
                      borderColor={
                        (item.department == selectManager &&
                          (Myprofile.department == "Director" ||
                            Myprofile.department == "human resources" ||
                            Myprofile.superAdmin == true)) ||
                        Myprofile.department == "HR"
                          ? "gray.50"
                          : "darkBrand"
                      }
                      borderRadius={"20px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <NFT
                        name={item.name}
                        author={item.role}
                        image={item.profilePic}
                        currentbid={Number(
                          (find?.communication +
                            find?.behaviour +
                            find?.ownership +
                            find?.teamwork +
                            find?.qualitywork +
                            find?.timelydeliver +
                            find?.goals +
                            find?.overall) /
                            8
                        ).toFixed(2)|| 0}
                        download="#"
                        itemAll={item}
                        
                      />
                    </Box>
                  );
                })}
                {
                Myprofile.superAdmin == true ||
                Myprofile.department == "Director" ||
                Myprofile.department == "human resources" ? null: support.map((item) => {
                    const find = AllMonthKpi.find((i) => {
                    return i.teamId == item._id
                    })
                  return (
                    <NFT
                      name={item.name}
                      author={"Support and Co-ordination"}
                      image={item.profilePic}
                      currentbid={Number(
                        (find?.communication +
                          find?.behaviour +
                          find?.ownership +
                          find?.teamwork +
                          find?.qualitywork +
                          find?.timelydeliver +
                          find?.goals +
                          find?.overall) /
                          8
                      ).toFixed(2)}
                      download="#"
                      itemAll={item}
                    />
                  );
                })}
                { Myprofile.department == "brand" ? seo.map((item) => {
                   const find = AllMonthKpi.find((i) => {
                    return i.teamId == item._id
                   })
                  return (
                    <NFT
                      name={item.name}
                      author={"SEO"}
                      image={item.profilePic}
                      currentbid={Number(
                        (find?.communication +
                          find?.behaviour +
                          find?.ownership +
                          find?.teamwork +
                          find?.qualitywork +
                          find?.timelydeliver +
                          find?.goals +
                          find?.overall) /
                          8
                      ).toFixed(2)}
                      download="#"
                      itemAll={item}
                    />
                  );
                }):null}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Grid>
      ) : null}
      {/* Team Product */}
      {Myprofile.department == "Director" ||
      Myprofile.superAdmin == true ||
      Myprofile.department == "human resources" ||   Myprofile.department == "sirjawad" ? (
        <Grid
          mb="20px"
          // gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
          gap={{ base: "40px", xl: "10px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            <Flex direction="column">
              <Flex
                mt="100px"
                mb="20px"
                justifyContent="space-between"
                direction={{ base: "column", md: "row" }}
                align={{ base: "start", md: "center" }}
              >
                <Text
                  color={textColor}
                  fontSize="2xl"
                  // ms="20px"
                  fontWeight="700"
                  bgGradient="linear(to-l, #ff0052,#FFF)"
                  bgClip="text"
                >
                  {selectManager == "design_night"
                    ? "Design TEAM (Night Shift)"
                    : selectManager.toUpperCase()}{" "}
                  {selectManager == "design_night" ? "" : "TEAM"}
                </Text>
                {Myprofile.department == "human resources" ? (
                  <Button
                    variant="darkBrand"
                    color="white"
                    fontSize="sm"
                    fontWeight="500"
                    bg={"#ff0052"}
                    borderRadius="70px"
                    onClick={() => send_Api_to_Team()}
                  >
                    Send Kpi{" to  "}
                    {selectManager == "design_night"
                      ? "Design TEAM (Night Shift)"
                      : selectManager.toUpperCase()}{" "}
                    {selectManager == "design_night" ? "" : "TEAM"}
                  </Button>
                ) : null}
              </Flex>
              <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
              {support.map((item) => {
                   const find = AllMonthKpi.find((i) => {
                    return i.teamId == item._id
                   })
                  return (
                    <NFT
                      name={item.name}
                      author={"Support and Co-ordination"}
                      image={item.profilePic}
                      currentbid={Number(
                        (find?.communication +
                          find?.behaviour +
                          find?.ownership +
                          find?.teamwork +
                          find?.qualitywork +
                          find?.timelydeliver +
                          find?.goals +
                          find?.overall) /
                          8
                      ).toFixed(2)}
                      download="#"
                      itemAll={item}
                    />
                  );
                })}
                {selectManager == "brand" ? seo.map((item) => {
                   const find = AllMonthKpi.find((i) => {
                    return i.teamId == item._id
                   })
                  return (
                    <NFT
                      name={item.name}
                      author={"SEO"}
                      image={item.profilePic}
                      currentbid={Number(
                        (find?.communication +
                          find?.behaviour +
                          find?.ownership +
                          find?.teamwork +
                          find?.qualitywork +
                          find?.timelydeliver +
                          find?.goals +
                          find?.overall) /
                          8
                      ).toFixed(2)}
                      download="#"
                      itemAll={item}
                    />
                  );
                }):null}
                {TeamData.map((item) => {
                     const find = AllMonthKpi.find((i) => {
                      return i.teamId == item._id
                     })
                  return (
                    <NFT
                      name={item.name}
                      author={item.role}
                      image={item.profilePic}
                      currentbid={Number(
                        (find?.communication +
                          find?.behaviour +
                          find?.ownership +
                          find?.teamwork +
                          find?.qualitywork +
                          find?.timelydeliver +
                          find?.goals +
                          find?.overall) /
                          8
                      ).toFixed(2)|| 0}
                      download="#"
                      itemAll={item}
                    />
                  );
                })}
                
              </SimpleGrid>
            </Flex>
          </Flex>
        </Grid>
      ) : null}
    </Box>
  );
}





  {/* Design  */}
  // {Brand?.map((item, index) => {
  //   console.log(item, "item");
  //   return (
  //     <div className="container">
  //       <div className="card">
  //         <div className="lines"></div>
  //         <div className="imageBox">
  //           <img
  //             src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
  //             alt="avatar"
  //           />
  //         </div>
  //         <div className="content">
  //           <div className="details">
  //             <h2>
  //               {allTeamData.map((itemer) => {
  //                 return (
  //                   <>
  //                     {itemer._id == item.teamId ? itemer.name : null}
  //                   </>
  //                 );
  //               })}
  //               <br />{" "}
  //               <span>
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? itemer.role : null}
  //                     </>
  //                   );
  //                 })}
  //               </span>
  //             </h2>
  //             <div className="info">
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //                 textAlign={"center"}
  //                 alignSelf={"center"}
  //               >
  //                 <h1>
  //                   Current KPI ={" "}
  //                   {Number(
  //                     (item.communication +
  //                       item.behaviour +
  //                       item.ownership +
  //                       item.teamwork +
  //                       item.qualitywork +
  //                       item.timelydeliver +
  //                       item.goals +
  //                       item.overall) /
  //                       8
  //                   ).toFixed(2)}
  //                 </h1>
  //               </Text>
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //               >
  //                 {""}
  //               </Text>
  //             </div>
  //             <div className="actions">
  //               <Link
  //                 href={"#/admin/singleprofile"}
  //                 mt={{
  //                   base: "0px",
  //                   md: "10px",
  //                   lg: "0px",
  //                   xl: "10px",
  //                 }}
  //               >
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? (
  //                         <Button
  //                           variant="darkBrand"
  //                           color="white"
  //                           fontSize="sm"
  //                           fontWeight="500"
  //                           bg={"#070707"}
  //                           marginRight={"10px"}
  //                           onClick={async () => {
  //                             dispatch(SelectProfile(itemer));
  //                             const items = JSON.stringify(itemer);
  //                             await localStorage.setItem(
  //                               "SelectProfile",
  //                               items
  //                             );
  //                           }}
  //                         >
  //                           View
  //                         </Button>
  //                       ) : null}
  //                     </>
  //                   );
  //                 })}
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // })}

  // {/* Design  */}
  // {Design?.map((item, index) => {
  //   console.log(item, "item");
  //   return (
  //     <div className="container">
  //       <div className="card">
  //         <div className="lines"></div>
  //         <div className="imageBox">
  //           <img
  //             src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
  //             alt="avatar"
  //           />
  //         </div>
  //         <div className="content">
  //           <div className="details">
  //             <h2>
  //               {allTeamData.map((itemer) => {
  //                 return (
  //                   <>
  //                     {itemer._id == item.teamId ? itemer.name : null}
  //                   </>
  //                 );
  //               })}
  //               <br />{" "}
  //               <span>
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? itemer.role : null}
  //                     </>
  //                   );
  //                 })}
  //               </span>
  //             </h2>
  //             <div className="info">
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //                 textAlign={"center"}
  //                 alignSelf={"center"}
  //               >
  //                 <h1>
  //                   Quarterly KPI ={" "}
  //                   {Number(
  //                     (item.communication +
  //                       item.behaviour +
  //                       item.ownership +
  //                       item.teamwork +
  //                       item.qualitywork +
  //                       item.timelydeliver +
  //                       item.goals +
  //                       item.overall) /
  //                       8
  //                   ).toFixed(2)}
  //                 </h1>
  //               </Text>
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //               >
  //                 {""}
  //                 {/* <h1>KPI</h1> */}
  //               </Text>
  //             </div>
  //             <div className="actions">
  //               <Link
  //                 href={"#/admin/singleprofile"}
  //                 mt={{
  //                   base: "0px",
  //                   md: "10px",
  //                   lg: "0px",
  //                   xl: "10px",
  //                 }}
  //               >
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? (
  //                         <Button
  //                           variant="darkBrand"
  //                           color="white"
  //                           fontSize="sm"
  //                           fontWeight="500"
  //                           bg={"#070707"}
  //                           marginRight={"10px"}
  //                           onClick={async () => {
  //                             dispatch(SelectProfile(itemer));
  //                             const items = JSON.stringify(itemer);
  //                             await localStorage.setItem(
  //                               "SelectProfile",
  //                               items
  //                             );
  //                           }}
  //                         >
  //                           View
  //                         </Button>
  //                       ) : null}
  //                     </>
  //                   );
  //                 })}
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // })}
  // {DesignNight?.map((item, index) => {
  //   console.log(item, "item");
  //   return (
  //     <div className="container">
  //       <div className="card">
  //         <div className="lines"></div>
  //         <div className="imageBox">
  //           <img
  //             src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
  //             alt="avatar"
  //           />
  //         </div>
  //         <div className="content">
  //           <div className="details">
  //             <h2>
  //               {allTeamData.map((itemer) => {
  //                 return (
  //                   <>
  //                     {itemer._id == item.teamId ? itemer.name : null}
  //                   </>
  //                 );
  //               })}
  //               <br />{" "}
  //               <span>
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? itemer.role : null}
  //                     </>
  //                   );
  //                 })}
  //               </span>
  //             </h2>
  //             <div className="info">
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //                 textAlign={"center"}
  //                 alignSelf={"center"}
  //               >
  //                 <h1>
  //                   Quarterly KPI ={" "}
  //                   {Number(
  //                     (item.communication +
  //                       item.behaviour +
  //                       item.ownership +
  //                       item.teamwork +
  //                       item.qualitywork +
  //                       item.timelydeliver +
  //                       item.goals +
  //                       item.overall) /
  //                       8
  //                   ).toFixed(2)}
  //                 </h1>
  //               </Text>
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //               >
  //                 {""}
  //                 {/* <h1>KPI</h1> */}
  //               </Text>
  //             </div>
  //             <div className="actions">
  //               <Link
  //                 href={"#/admin/singleprofile"}
  //                 mt={{
  //                   base: "0px",
  //                   md: "10px",
  //                   lg: "0px",
  //                   xl: "10px",
  //                 }}
  //               >
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? (
  //                         <Button
  //                           variant="darkBrand"
  //                           color="white"
  //                           fontSize="sm"
  //                           fontWeight="500"
  //                           bg={"#070707"}
  //                           marginRight={"10px"}
  //                           onClick={async () => {
  //                             dispatch(SelectProfile(itemer));
  //                             const items = JSON.stringify(itemer);
  //                             await localStorage.setItem(
  //                               "SelectProfile",
  //                               items
  //                             );
  //                           }}
  //                         >
  //                           View
  //                         </Button>
  //                       ) : null}
  //                     </>
  //                   );
  //                 })}
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // })}

  // {/* Design  */}
  // {Design?.map((item, index) => {
  //   console.log(item, "item");
  //   return (
  //     <div className="container">
  //       <div className="card">
  //         <div className="lines"></div>
  //         <div className="imageBox">
  //           <img
  //             src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
  //             alt="avatar"
  //           />
  //         </div>
  //         <div className="content">
  //           <div className="details">
  //             <h2>
  //               {allTeamData.map((itemer) => {
  //                 return (
  //                   <>
  //                     {itemer._id == item.teamId ? itemer.name : null}
  //                   </>
  //                 );
  //               })}
  //               <br />{" "}
  //               <span>
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? itemer.role : null}
  //                     </>
  //                   );
  //                 })}
  //               </span>
  //             </h2>
  //             <div className="info">
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //                 textAlign={"center"}
  //                 alignSelf={"center"}
  //               >
  //                 <h1>
  //                   Quarterly KPI ={" "}
  //                   {Number(
  //                     (item.communication +
  //                       item.behaviour +
  //                       item.ownership +
  //                       item.teamwork +
  //                       item.qualitywork +
  //                       item.timelydeliver +
  //                       item.goals +
  //                       item.overall) /
  //                       8
  //                   ).toFixed(2)}
  //                 </h1>
  //               </Text>
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //               >
  //                 {""}
  //               </Text>
  //             </div>
  //             <div className="actions">
  //               <Link
  //                 href={"#/admin/singleprofile"}
  //                 mt={{
  //                   base: "0px",
  //                   md: "10px",
  //                   lg: "0px",
  //                   xl: "10px",
  //                 }}
  //               >
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? (
  //                         <Button
  //                           variant="darkBrand"
  //                           color="white"
  //                           fontSize="sm"
  //                           fontWeight="500"
  //                           bg={"#070707"}
  //                           marginRight={"10px"}
  //                           onClick={async () => {
  //                             dispatch(SelectProfile(itemer));
  //                             const items = JSON.stringify(itemer);
  //                             await localStorage.setItem(
  //                               "SelectProfile",
  //                               items
  //                             );
  //                           }}
  //                         >
  //                           View
  //                         </Button>
  //                       ) : null}
  //                     </>
  //                   );
  //                 })}
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // })}
  // {/*           
  // Brand */}
  // {brand?.map((item, index) => {
  //   console.log(item, "item");
  //   return (
  //     <div className="container">
  //       <div className="card">
  //         <div className="lines"></div>
  //         <div className="imageBox">
  //           <img
  //             src="https://i.ebayimg.com/images/g/8NUAAOSw38pg0k6n/s-l1600.jpg"
  //             alt="avatar"
  //           />
  //         </div>
  //         <div className="content">
  //           <div className="details">
  //             <h2>
  //               {allTeamData.map((itemer) => {
  //                 return (
  //                   <>
  //                     {itemer._id == item.teamId ? itemer.name : null}
  //                   </>
  //                 );
  //               })}
  //               <br />{" "}
  //               <span>
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? itemer.role : null}
  //                     </>
  //                   );
  //                 })}
  //               </span>
  //             </h2>
  //             <div className="info">
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //                 textAlign={"center"}
  //                 alignSelf={"center"}
  //               >
  //                 <h1>
  //                   Quarterly KPI ={" "}
  //                   {Number(
  //                     (item.communication +
  //                       item.behaviour +
  //                       item.ownership +
  //                       item.teamwork +
  //                       item.qualitywork +
  //                       item.timelydeliver +
  //                       item.goals +
  //                       item.overall) /
  //                       8
  //                   ).toFixed(2)}
  //                 </h1>
  //               </Text>
  //               <Text
  //                 color={textColor}
  //                 fontSize="2xl"
  //                 // ms="24px"
  //                 fontWeight="700"
  //               >
  //                 {""}
  //               </Text>
  //             </div>
  //             <div className="actions">
  //               <Link
  //                 href={"#/admin/singleprofile"}
  //                 mt={{
  //                   base: "0px",
  //                   md: "10px",
  //                   lg: "0px",
  //                   xl: "10px",
  //                 }}
  //               >
  //                 {allTeamData.map((itemer) => {
  //                   return (
  //                     <>
  //                       {itemer._id == item.teamId ? (
  //                         <Button
  //                           variant="darkBrand"
  //                           color="white"
  //                           fontSize="sm"
  //                           fontWeight="500"
  //                           bg={"#070707"}
  //                           marginRight={"10px"}
  //                           onClick={async () => {
  //                             dispatch(SelectProfile(itemer));
  //                             const items = JSON.stringify(itemer);
  //                             await localStorage.setItem(
  //                               "SelectProfile",
  //                               items
  //                             );
  //                           }}
  //                         >
  //                           View
  //                         </Button>
  //                       ) : null}
  //                     </>
  //                   );
  //                 })}
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // })}