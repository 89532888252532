export const columnsDataTeam = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Department",
    accessor: "department",
  },

  {
    Header: "Delete",
    accessor: "_id",
  },
];
