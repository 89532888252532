import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    profile: [],
    chartData: [],
    teamperfomance: [],
    teamperfomanceName: [],
    selectProfile: [],
  },
  reducers: {
    Profile: (state, actions) => {
      state.profile = actions.payload;
    },
    ChartData: (state, actions) => {
      state.chartData = actions.payload;
    },

    TeamPerfomance: (state, actions) => {
      state.teamperfomance = actions.payload;
    },
    TeamPerfomanceName: (state, actions) => {
      state.teamperfomanceName = actions.payload;
    },
    SelectProfile: (state, actions) => {
      state.selectProfile = actions.payload;
    },
  },
});

export const {
  Profile,
  ChartData,
  TeamPerfomance,
  TeamPerfomanceName,
  SelectProfile,
} = counterSlice.actions;
export default counterSlice.reducer;
