// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { baseURL } from "constant/api";
import React, { useEffect, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import {
  lineChartDataTotalSpent,
  // lineChartOptionsTotalSpent,
} from "variables/charts";

export default function TotalSpent(props) {
  const { ...rest } = props;
  const [allKpi, setAllKpi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [KpiData, setKpiData] = useState([]);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  useEffect(() => {
    setLoading(true);
    fetch(`${baseURL}api/kpi/par_kpi_array?id=${props.teamId}`)
      .then((resp) => resp.json())
      .then((responsek) => {
        fetch(`${baseURL}api/kpi/par_kpi?id=${props.teamId}`)
          .then((resp) => resp.json())
          .then((response) => {
            setKpiData(response);
            const kpiAll =
              allKpi[0] == currentMonthKpi ? responsek.reverse() : responsek;
            setAllKpi(kpiAll);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [props.setUpdate]);
  const Current_Kpi =
    KpiData[0]?.communication +
    KpiData[0]?.behaviour +
    KpiData[0]?.ownership +
    KpiData[0]?.teamwork +
    KpiData[0]?.qualitywork +
    KpiData[0]?.timelydeliver +
    KpiData[0]?.overall +
    KpiData[0]?.goals;
  const currentMonthKpi = Current_Kpi / 8;
  const lineChartDataTotalSpent1 = [
    {
      name: "Perfomance",
      data: allKpi,
    },
  ];
  const lineChartOptionsTotalSpent = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#ff0052",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },

    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories:
        KpiData[0]?.month == "october" || KpiData[1]?.month == "october"
          ? [
              "OCT",
              "NOV",
              "DEC",
              "JAN",
              "FEB",
              "MAR",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
            ]
          : [
              "NOV",
              "DEC",
              "JAN",
              "FEB",
              "MAR",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
              "OCT",
            ],
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      color: "black",
      showForNullSeries: true,
      seriesName: "Kpi",
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 15,
      forceNiceScale: false,
     
      labels: {
        show: true,
        style: {
          colors: "#ff0052",
          fontSize: "14px",
        },
      },
      min: 1,
      max: 5,
      tickAmount: 4
      
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 5,
    },
    color: ["#7551FF", "#39B8FF"],
  };

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          <Button
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            color={textColorSecondary}
            borderRadius="7px"
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
            />
            Overall Performance
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" minW="75%" mt="auto">
          {loading == true ? (
            <LoadingSpinner />
          ) : (
            <LineChart
              chartData={lineChartDataTotalSpent1}
              chartOptions={lineChartOptionsTotalSpent}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
}
