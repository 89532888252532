export const columnsDataDevelopment = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Communication",
    accessor: "communication",
  },

  {
    Header: "Behaviour",
    accessor: "behaviour",
  },
  {
    Header: "Ownership",
    accessor: "ownership",
  },
  {
    Header: "Teamwork",
    accessor: "teamwork",
  },
  {
    Header: "Quality Work",
    accessor: "qualitywork",
  },
  {
    Header: "Timely Deliver",
    accessor: "timelydeliver",
  },

  {
    Header: "OVERALL",
    accessor: "overall",
  },
];

export const developmentsData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Techinal expertise based on work experience",
    accessor: "communication",
  },

  {
    Header: "Understanding of project requirement",
    accessor: "behaviour",
  },
  {
    Header: "Out of the box approach",
    accessor: "ownership",
  },
  {
    Header: "Pressure and workload management",
    accessor: "teamwork",
  },
  {
    Header: "Coding Standards",
    accessor: "qualitywork",
  },
  {
    Header: "Meeting Timelines",
    accessor: "timelydeliver",
  },

  {
    Header:
      "Mananging multiple projects at the same time without hurting any deadline",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },

  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const salesData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Communication",
    accessor: "communication",
  },

  {
    Header: "Monthly Target Consistency",
    accessor: "behaviour",
  },
  {
    Header: "Project Description and Clarity",
    accessor: "ownership",
  },
  {
    Header: "Client's Satisfaction",
    accessor: "teamwork",
  },
  {
    Header: "Timely Deliveries",
    accessor: "qualitywork",
  },
  {
    Header: "Teamwork and Trainings",
    accessor: "timelydeliver",
  },

  {
    Header: "Proposals/Bidding",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const DigitalData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Planning and execution of all digital marketing activities",
    accessor: "communication",
  },

  {
    Header: "Overall performance in designing, building, and maintaining our social media presence",
    accessor: "behaviour",
  },
  {
    Header: "Performance in terms of providing Sales Qualified Leads (SQL)",
    accessor: "ownership",
  },
  {
    Header: "Maintaining the Cost per lead (CPL) to efficiently utilize the marketing budget",
    accessor: "teamwork",
  },
  {
    Header: "Measuring and reporting the performance of all digital marketing campaigns, and assessing against goals (ROI and KPIs set by management)",
    accessor: "qualitywork",
  },
  {
    Header: "Team management and catering to their queries",
    accessor: "timelydeliver",
  },

  {
    Header: "Identification of trends and insights, and optimize spend and performance based on the insights",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const seoData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Research Work (Understanding of brief and client niche)",
    accessor: "communication",
  },

  {
    Header: "Performance related to Keyword Ranking ",
    accessor: "behaviour",
  },
  {
    Header: "Quality and Number of Organic Leads",
    accessor: "ownership",
  },
  {
    Header: "Following technical and industry standards",
    accessor: "teamwork",
  },
  {
    Header: "Back-Linking Capabilities",
    accessor: "qualitywork",
  },
  {
    Header: "Organic CTR Performance",
    accessor: "timelydeliver",
  },

  {
    Header: "Project Handling Capabilities",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const QaData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Rejected Defects",
    accessor: "communication",
  },

  {
    Header: "Reviewing Requirements",
    accessor: "behaviour",
  },
  {
    Header: "Communication skills",
    accessor: "ownership",
  },
  {
    Header: "Closure ratio",
    accessor: "teamwork",
  },
  {
    Header: "Functional Testing",
    accessor: "qualitywork",
  },
  {
    Header: "Pressure and workload management",
    accessor: "timelydeliver",
  },

  {
    Header: "Process automation",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const DesignData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Requirements Gathering",
    accessor: "communication",
  },

  {
    Header: "Design",
    accessor: "behaviour",
  },
  {
    Header: "Review",
    accessor: "ownership",
  },
  {
    Header: "Delivery",
    accessor: "teamwork",
  },
  {
    Header: "New design trends",
    accessor: "qualitywork",
  },
  {
    Header: "Design quality",
    accessor: "timelydeliver",
  },

  {
    Header: "Industry/technical standards",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const operationData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Training & development",
    accessor: "communication",
  },

  {
    Header: "Goals progress",
    accessor: "behaviour",
  },
  {
    Header: "Team efficiency",
    accessor: "ownership",
  },
  {
    Header: "Reporting and updates",
    accessor: "teamwork",
  },
  {
    Header: "Financial goals",
    accessor: "qualitywork",
  },
  {
    Header: "Supervision",
    accessor: "timelydeliver",
  },

  {
    Header: "Ensuring SOPs, Policies and Quality",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const projectCoordinatorData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Understanding of Project",
    accessor: "communication",
  },

  {
    Header: "Strategize",
    accessor: "behaviour",
  },
  {
    Header: "Business communication",
    accessor: "ownership",
  },
  {
    Header: "Collaboration, coordination",
    accessor: "teamwork",
  },
  {
    Header: "Portal management",
    accessor: "qualitywork",
  },
  {
    Header: "Assessment of Project profitability",
    accessor: "timelydeliver",
  },

  {
    Header: "Assessment of Resource Working Hours",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const ContentWritterData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Skillset readability",
    accessor: "communication",
  },

  {
    Header: "Writing ability in different domains",
    accessor: "behaviour",
  },
  {
    Header: "Quality of writing",
    accessor: "ownership",
  },
  {
    Header: "Research work",
    accessor: "teamwork",
  },
  {
    Header: "Turn around time",
    accessor: "qualitywork",
  },
  {
    Header: "Consistency level",
    accessor: "timelydeliver",
  },

  {
    Header: "Proofreading Ability",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const FinanceData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Bookkeeping & Payroll management",
    accessor: "communication",
  },

  {
    Header: "Taxation & Audit responsibilities",
    accessor: "behaviour",
  },
  {
    Header: "Compliance,Complaints &  Notice and Laegal documentaion",
    accessor: "ownership",
  },
  {
    Header: "Reporting & Budgeting",
    accessor: "teamwork",
  },
  {
    Header: "Banking & Fund Management",
    accessor: "qualitywork",
  },
  {
    Header: "Financial Data",
    accessor: "timelydeliver",
  },

  {
    Header: "Sales operations",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const AssistantAccountData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Timely payments",
    accessor: "communication",
  },

  {
    Header: "Bookkeeping & Taxation",
    accessor: "behaviour",
  },
  {
    Header: "Data Management",
    accessor: "ownership",
  },
  {
    Header: "Accuracy",
    accessor: "teamwork",
  },
  {
    Header: "Technical Skill",
    accessor: "qualitywork",
  },
  {
    Header: "Cash Management",
    accessor: "timelydeliver",
  },

  {
    Header: "Vendor management",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const ItManagerData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Organization & Delegation",
    accessor: "communication",
  },

  {
    Header: "Planning & Strategic Thinking",
    accessor: "behaviour",
  },
  {
    Header: "Problem Solving & Decision Making",
    accessor: "ownership",
  },
  {
    Header: "Technical & Conceptual",
    accessor: "teamwork",
  },
  {
    Header: "Communication & Motivation",
    accessor: "timelydeliver",
  },
  {
    Header: "Leadership & Mentoring",
    accessor: "qualitywork",
  },
  {
    Header: "Reporting & Updates",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },
  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const AssitantItData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Hardware & software maintenance",
    accessor: "communication",
  },

  {
    Header: "Network & Infrastructure Upgradation",
    accessor: "behaviour",
  },
  {
    Header: "Ticketing System",
    accessor: "ownership",
  },
  {
    Header: "Documents & Data management",
    accessor: "teamwork",
  },
  {
    Header: "Problem Solving or Task handling",
    accessor: "qualitywork",
  },
  {
    Header: "Budgeting",
    accessor: "timelydeliver",
  },

  {
    Header: "Pressure handling",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];


export const ECommerceData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Product Research and Sorting ",
    accessor: "communication",
  },

  {
    Header: "Product Sourcing ",
    accessor: "behaviour",
  },
  {
    Header: "Amazon PPC Expertise",
    accessor: "ownership",
  },
  {
    Header: "Optimization of PPC",
    accessor: "teamwork",
  },
  {
    Header: "Product Keyword Research",
    accessor: "qualitywork",
  },
  {
    Header: "Product Listing",
    accessor: "timelydeliver",
  },

  {
    Header: "Order Management Efficiency",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];

export const HumanResourcesData = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Compensation & Benefit management",
    accessor: "communication",
  },

  {
    Header: "Attendance/Payroll",
    accessor: "behaviour",
  },
  {
    Header: "Performance management processes",
    accessor: "ownership",
  },
  {
    Header: "Recruitment",
    accessor: "teamwork",
  },
  {
    Header: "Onboarding & ongoing training and development plans",
    accessor: "qualitywork",
  },
  {
    Header: "Policy Management",
    accessor: "timelydeliver",
  },

  {
    Header: "Platform,Documentation/Drive Management",
    accessor: "overall",
  },
  {
    Header:
      "Interpersonal Skills ( Effective Communication , TeamWork, Good Behaviour, Emotional Intelligence, Self Management)",
    accessor: "goals",
  },

  {
    Header: "Description Box",
    accessor: "descriptionbox",
  },
  {
    Header: "Overall Kpi",
    accessor: "kpi",
  },
  {
    Header: "Send Email",
    accessor: "email",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Download",
    accessor: "download",
  },
];
export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
