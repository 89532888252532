/* eslint-disable */
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import { baseURL } from "constant/api";
import { CurrentMonth } from "constant/currentMonth";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import UpdateKpi from "views/admin/rtl/components/UpdateKpi";
import Register from "views/auth/Register";

export default function AllTeamTable(props) {
  const { columnsData, tableData } = props;
  console.log(tableData, "tableData");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [EditKpi, setEditKpi] = useState(false);
  const [SelectData, setSelectData] = useState();
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  // initialState: {
  //   pageIndex: 2;
  // }
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  //For State hook to save the All Kpi
  const loginData = localStorage.getItem("login");
  const Profile = JSON.parse(loginData);
  const [AddMemeber, setMemeber] = useState(false);

  const DeleteKpi = (id) => {
    fetch(`https://www.api.codpixels.com/api/auth/deletemember?id=${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((response) => {
        alert(response.message);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {AddMemeber == false ? (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "scroll" }}
        >
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              All team member
            </Text>
            <Button
              variant="darkBrand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              bg={"#ff0052"}
              borderRadius="70px"
              onClick={async () => {
                setMemeber(true);
              }}
            >
              Add Member
            </Button>
          </Flex>

          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="3px"
                      key={index}
                      borderColor={borderColor}
                      // borderWidth={'thick'}
                    >
                      {column.render("Header") !== "Id" ? (
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                          // width={'200px'}
                        >
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                            textAlign={"center"}
                          >
                            {column.render("Header")}
                          </Text>
                        </Flex>
                      ) : null}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index1) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index1}>
                    {row.cells.map((cell, index) => {
                      console.log(
                        tableData[index]._id,
                        "tableData[index].id",
                        cell
                      );
                      let data = "";
                      if (cell.column.Header === "Name") {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Email") {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                              padding={"1"}
                              textAlign={"center"}
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Role") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Department") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Delete") {
                        data = (
                          <Flex align="center" alignItems={"center"}>
                            <DeleteIcon
                              cursor={"pointer"}
                              w={5}
                              h={5}
                              color="red.500"
                              onClick={() => {
                                DeleteKpi(cell.value);
                              }}
                            />
                          </Flex>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
            <Flex
              justifyContent="space-between"
              m={4}
              alignItems="center"
              width={"200%"}
              // borderWidth={'thick'}
            >
              <Flex>
                <Tooltip label="First Page">
                  <IconButton
                    onClick={() => gotoPage(0)}
                    isDisabled={!canPreviousPage}
                    icon={<ArrowLeftIcon h={3} w={3} />}
                    color={"white"}
                    mr={4}
                  />
                </Tooltip>
                <Tooltip label="Previous Page">
                  <IconButton
                    onClick={previousPage}
                    isDisabled={!canPreviousPage}
                    color={"white"}
                    icon={<ChevronLeftIcon h={6} w={6} />}
                  />
                </Tooltip>
              </Flex>

              <Flex alignItems="center">
                <Text color={"white"} flexShrink="0" mr={8}>
                  Page{" "}
                  <Text color={"white"} fontWeight="bold" as="span">
                    {pageIndex + 1}
                  </Text>{" "}
                  of{" "}
                  <Text color={"white"} fontWeight="bold" as="span">
                    {pageOptions.length}
                  </Text>
                </Text>
                <Text flexShrink="0" color={"white"}>
                  Go to page:
                </Text>{" "}
                <NumberInput
                  ml={2}
                  mr={8}
                  w={28}
                  min={1}
                  color={"white"}
                  max={pageOptions.length}
                  onChange={(value) => {
                    const page = value ? value - 1 : 0;
                    gotoPage(page);
                  }}
                  defaultValue={pageIndex + 1}
                >
                  <NumberInputField color={"white"} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  w={32}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex>
                <Tooltip label="Next Page">
                  <IconButton
                    onClick={nextPage}
                    isDisabled={!canNextPage}
                    icon={<ChevronRightIcon color={"white"} h={6} w={6} />}
                  />
                </Tooltip>
                <Tooltip label="Last Page">
                  <IconButton
                    onClick={() => gotoPage(pageCount - 1)}
                    isDisabled={!canNextPage}
                    icon={<ArrowRightIcon color={"white"} h={3} w={3} />}
                    ml={4}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </Table>
        </Card>
      ) : (
        <Register stateChange={setMemeber} />
      )}
    </>
  );
}
