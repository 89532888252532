// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React, { useState } from "react";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import moment from "moment";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("#ff0052", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("#ff0052", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const [chartData, setChartData] = React.useState([]);
  const [loading_Chart, setLoading_Chart] = React.useState(false);
  const [Year, setyear] = useState("2022");
  const ProfileSelect = useSelector((store) => store.counter.chartData);
  const loginData = localStorage.getItem("login");
  const Myprofile = JSON.parse(loginData);
  // const Myprofile = useSelector((store) => store.counter.profile);
  React.useEffect(() => {
    setLoading_Chart(true);
    fetch(`${baseURL}api/kpi/par_kpi?id=${Myprofile._id}`)
      .then((resp) => resp.json())
      .then((response2) => {
        // setAllKpiData(response2);
        const chart = [
          {
            name: "My Performance",
            data: [
              response2.find((item) => item.month == "january") == undefined
                ? 0
                : Number(
                    response2.find(
                      (item) =>
                        item.month == "january" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).behaviour +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).ownership +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).teamwork +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).overall +
                      response2.find(
                        (item) =>
                          item.month == "january" &&
                          moment(item.createdAt).format("YYYY") == Year
                      ).goals /
                        8
                  ).toFixed(2),
              response2.find((item) => item.month == "february") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "february" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "february" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "march") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "march" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "march" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "april") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "april" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "april" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "may") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "may" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "may" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "june") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "june" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "june" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "july") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "july" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "july" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "august") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "august" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "august" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "september") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "september" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "september" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "october") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "october" &&
                        moment(item.createdAt).format("YYYY") == Year
                    ).communication +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "october" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "november") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "november" &&
                        moment(item.createdAt).format("YYYY") == Year
                    )?.communication +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "november" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
              response2.find((item) => item.month == "december") == undefined
                ? 0
                : Number(
                    (response2.find(
                      (item) =>
                        item.month == "december" &&
                        moment(item.createdAt).format("YYYY") == Year
                    )?.communication +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.behaviour +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.ownership +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.teamwork +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.qualitywork +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.timelydeliver +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.overall +
                      response2.find(
                        (item) =>
                          item.month == "december" &&
                          moment(item.createdAt).format("YYYY") == Year
                      )?.goals) /
                      8
                  ).toFixed(2),
            ],
          },
        ];
        // dispatch(ChartData(chart));\

      
        setChartData(chart);
        setLoading_Chart(false);
      })
      .catch((err) => console.log(err));
  }, [Year]);
  return (
    <>
      {/* <Card
        align="center"
        direction="column"
        w="30%"
        style={{ marginBottom: "10px" }}
      ></Card> */}

      <Card align="center" direction="column" w="100%" {...rest}>
        <Flex align="center" w="100%" px="15px" py="10px">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            {props.name || "My"} Performance
          </Text>

          {/* <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button> */}
        </Flex>
        <Card
          align="center"
          direction="column"
          w="30%"
          style={{ marginBottom: "5px" }}
        >
          <Select onChange={(event) => setyear(event.currentTarget.value)}>
            <option value={"2022"}>2022</option>
            <option value={"2023"}>2023</option>
          </Select>
        </Card>

        <Box h="240px" mt="auto">
          {loading_Chart == true ? (
            <LoadingSpinner />
          ) : (
            <BarChart
              chartData={chartData}
              chartOptions={barChartOptionsConsumption}
            />
          )}
        </Box>
      </Card>
    </>
  );
}
