// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Badge,
  HStack,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { ThemeEditor } from "./ThemeEditor";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "redux/reducer";
import { ChartData } from "redux/reducer";
import { TeamPerfomance } from "redux/reducer";
import { TeamPerfomanceName } from "redux/reducer";
import { useHistory } from "react-router-dom";
import { CurrentMonth } from "constant/currentMonth";
import { baseURL } from "constant/api";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const loginData = localStorage.getItem("login");
  const ProfileI = JSON.parse(loginData);
  // const ProfileI = useSelector((store) => store.counter.profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const [team, setTeam] = useState([]);
  const [kpi, setKpi] = useState([]);
  useEffect(() => {
    fetch(
      `${baseURL}api/auth/teamname?department=${ProfileI.department}&manager=${
        ProfileI.department == "Director" ? true : false
      }`
    )
      .then((resp) => resp.json())
      .then((response1) => {
        setTeam(response1);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(
      `${baseURL}api/kpi/all_kpi_mon?id=${ProfileI._id}&month=${CurrentMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        // setTeamKpi(response);
        setKpi(response);
        // dispatch(TeamPerfomanceName(response));
        // history.push("/admin/default");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const Logout = () => {
    dispatch(Profile([]));
    dispatch(ChartData([]));
    dispatch(TeamPerfomance([]));
    dispatch(TeamPerfomanceName([]));
    localStorage.removeItem("login");
    history.push("/auth/sign-in");
  };
  // console.log(ProfileI.name, "check name");
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me="10px"
        borderRadius="30px"
      /> */}

      <FixedPlugin
        mb={{ base: "10px", md: "unset" }}
        me="100px"
        top="10px"
        borderRadius="30px"
        
      />
      <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      ></Flex>
      <SidebarResponsive routes={routes} />
      {ProfileI.manager == true || ProfileI.isAdmin == true ? (
        <Menu>
          <MenuButton p="0px">
            <HStack spacing={1}>
              {team.length == kpi.length ? null : (
                <Badge
                  variant={"solid"}
                  bg={"red"}
                  color="white"
                  fontSize={"8px"}
                  size="sm"
                  zIndex={"1"}
                >
                  1
                </Badge>
              )}
            </HStack>
            <Icon
              mt={team.length == kpi.length ? "15px" : "-9px"}
              as={MdNotificationsNone}
              color={navbarIcon}
              w="20px"
              h="20px"
              me="20px"
              marginBottom={"8px"}
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                {/* <ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" /> */}
              </MenuItem>
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent
                  info="Horizon Design System Free"
                  aName="Josh Henry"
                />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      ) : null}

      {/* <ThemeEditor navbarIcon={navbarIcon} /> */}

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="black"
            name={ProfileI.name}
            bg="white"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {ProfileI.name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              onClick={Logout}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm" color={textColor}>
                Log out
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
