import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "redux/store";
import { Profile } from "redux/reducer";

const AuthAdmin = () => {
  const profile = []
  const loginData = localStorage.getItem("login");
  const Login_Data = profile.push(JSON.parse(loginData));
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const ProfileId = useSelector((store) => store.counter.profile);
  useEffect(() => {
    toggleColorMode();
    dispatch(Profile(profile));
    // dispatch(Profile(ProfileId));
  }, []);

  // console.log(profile, "profilerx", ProfileId);
  return (
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/admin`} component={AdminLayout} />
      {profile[0]?._id && <Route path={`/admin`} component={AdminLayout} />}
      <Redirect from="/" to="/auth" />
    </Switch>
  );
};
export default AuthAdmin;
