// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { SelectProfile } from "redux/reducer";

export default function NFT(props) {
  const { image, name, author, bidders, download, currentbid, itemAll } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const dispatch = useDispatch();
  return (
    <Card p="10px">
      <Flex direction={{ base: "column" }} justify="center">
        {/* <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
          <Image
            src={image}
            w={{ base: "100%" }}
            h={{ base: "200px" }}
            borderRadius="20px"
          />
        </Box> */}
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: "xl",
                  md: "lg",
                  lg: "lg",
                  xl: "lg",
                  "2xl": "md",
                  "3xl": "lg",
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                {author}
              </Text>
            </Flex>
          </Flex>
          <Flex
            align="start"
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >
            <Text fontWeight="700" fontSize="sm" color={textColorBid}>
              Current Kpi: {currentbid == 'NaN' ? "0": currentbid}
            </Text>
            <Link
              href={"#/admin/singleprofile"}
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
            >
              <Button
                variant="darkBrand"
                color="white"
                fontSize="sm"
                fontWeight="500"
                bg={"#ff0052"}
                borderRadius="70px"
                onClick={async () => {
                  dispatch(SelectProfile(itemAll));
                  // dispatch(SelectProfile(itemer));
                  const items = JSON.stringify(itemAll);
                  await localStorage.setItem("SelectProfile", items);
                }}
              >
                {/* <CSVLink
                  data={[
                    [
                      "Name",
                      "Month",
                      "Communication",
                      "Punctuality",
                      "Behaviour",
                      "Ownership",
                      "TeamWord",
                      "Quality work",
                      "Timely Deliver",
                      "Job Specific",
                      "Overall Progress",
                    ],
                    [name, "September", 3, 3, 3, 3, 3, 3, 3, 3, currentbid],
                  ]}
                > */}
                View Perfomance
                {/* </CSVLink> */}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
