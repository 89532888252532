import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
// Custom components

// Assets
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import { CurrentMonth } from "constant/currentMonth";
import { parse } from "stylis";
import { projectCoordinatorData } from "views/admin/quarterly/variables/columnsData";

export default function ManagersOrLead({
  depart,
  setSelectedMemeber1,
  appliance,
  setValue,
  setSelectedMonth,
}) {
  const [data, setData] = React.useState([]);
  const [selectedMember, setSelectedMemeber] = React.useState();
  const [selectManager, setSelectManger] = React.useState("development");
  const [TeamData, setTeamData] = React.useState([]);
  const [communication, setcommunication] = React.useState("0");
  const [behaviour, setbehaviour] = React.useState("0");
  const [ownership, setownership] = React.useState("0");
  const [teamwork, setteamwork] = React.useState("0");
  const [qualitywork, setqualitywork] = React.useState("0");
  const [timelydeliver, settimelydeliver] = React.useState("0");
  const [overall, setoverall] = React.useState(0);
  const [descriptionbox, setDescriptionBox] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [departmentTable, setDepartment] = React.useState("");
  const [AllManagersDepart, setAllManagersDepart] = useState([]);
  const [department, setDepart] = useState("");

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.toLowerCase() !== "naruto") {
      error = "Jeez! You're not a fan 😱";
    }
    return error;
  }
  const form = useRef();
  const loginData = localStorage.getItem("login");
  const ProfileId = JSON.parse(loginData);

  // const ProfileId = useSelector((store) => store.counter.profile);
  const parse = (val) => val.replace(/^\$/, "");
  //Team Data
  React.useEffect(() => {
    ProfileId.department == "Director" ||
    ProfileId.department == "HR" ||
    ProfileId.department == "sirjawad"
      ? fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            if (ProfileId.role == "Bussiness Unit Head") {
              var newArray = response.filter(function (el) {
                return (
                  el.compliance == (appliance == true ? true : false) &&
                  (el.department == "brand designer" ||
                    el.department == "brand")
                );
              });

              setData(newArray);
            } else {
              var newArray = response.filter(function (el) {
                return (
                  el.compliance == (appliance == true ? true : false) &&
                  el.department != "brand designer" &&
                  el.department != "brand"
                );
              });

              setData(newArray);
            }
            setSelectedMemeber(response[0]._id);
            setAllManagersDepart(response.map((item) => item.department));
          })
          .catch((err) => {
            console.log(err);
          })
      : fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
          .then((resp) => resp.json())
          .then((response) => {
            setData(response);
            setSelectedMemeber(response[0]._id);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);
  React.useEffect(() => {
    setSelectedMemeber1(selectedMember);
    setValue(selectedMember);
  }, [selectedMember]);
  useEffect(() => {
    //For Team
    fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
      .then((resp) => resp.json())
      .then((response) => {
        setTeamData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [depart]);
  const overallValue =
    Number(communication) +
    Number(behaviour) +
    Number(ownership) +
    Number(teamwork) +
    Number(qualitywork) +
    Number(timelydeliver);
  //For Submit Kpi
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const CurrentMonth1 = months[new Date().getMonth()];
  const NextMonth =
    months[CurrentMonth == "january" ? 11 : new Date().getMonth() - 1];
  const [month, setMonth] = React.useState(NextMonth);
  const [goal, setGoal] = React.useState("0");
  useEffect(() => {
    setSelectedMonth(month);
  }, [month]);
  useEffect(() => {
    fetch(`${baseURL}api/kpi/par_kpi?id=${selectedMember}`)
      .then((resp) => resp.json())
      .then((response) => {
        setDepart(response[0]?.department);
        // console.log()
      })
      .catch((err) => console.log(err));
  }, [selectedMember]);
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const bodyObject = {
      teamId: selectedMember,
      name: "Muhammad",
      managerId: ProfileId._id,
      month: month,
      communication: communication,
      behaviour: behaviour,
      ownership: ownership,
      teamwork: teamwork,
      qualitywork: qualitywork,
      timelydeliver: timelydeliver,
      overall: overall,
      manager: true,
      department: depart,
      descriptionbox: descriptionbox,
      goals: goal,
    };
    fetch(`${baseURL}api/kpi/addkpi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        alert(response.message, "response");
        setcommunication("0");
        setbehaviour("0");
        setownership("0");
        setteamwork("0");
        setqualitywork("0");
        settimelydeliver("0");
        setoverall("0");
        setGoal("0");
        setDescriptionBox("");
        setSelectedMemeber("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Box pt={{ base: "180px", md: "180px", xl: "10px" }}>
      <form ref={form} onSubmit={handleSubmit}>
        <FormControl id="teammember" isRequired>
          <FormLabel>Select team member name</FormLabel>
          <Select
            placeholder="Select"
            value={selectedMember}
            onChange={(event) => setSelectedMemeber(event.currentTarget.value)}
          >
            {data.map((item) => {
              return <option value={item._id}>{item.name}</option>;
            })}
          </Select>
        </FormControl>
        <FormControl id="Month" isRequired>
          <FormLabel>Select Month</FormLabel>
          <Select onChange={(event) => setMonth(event.currentTarget.value)}>
            <option value={NextMonth}>
              {NextMonth.charAt(0).toUpperCase() + NextMonth.slice(1)}
            </option>
            <option value={CurrentMonth}>
              {CurrentMonth.charAt(0).toUpperCase() + CurrentMonth.slice(1)}
            </option>
          </Select>
        </FormControl>
        <FormControl isRequired id="Communication" top={"2px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Compensation & Benefit management: Health insurance OPD · Gifts (Marriage/ Birth of baby) · SESSI · EOBI etc ."
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Product Research and Sorting"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Planning and execution of all digital marketing activities "
              : department == "project coordination"
              ? projectCoordinatorData[1].Header
              : "Organization & Delegation."}
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={communication}
            max={5.0}
            min={1.0}
            step={0.5}
            contentEditable={false}
            onChange={(valueString) => setcommunication(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="ownership" top={"10px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Attendance/Payroll Complete all payroll activities by 25th of each  month Keep track of all employee attendance and update managers on weekly basis Encourage employees to take vacations and time off on a regular basis specially managers so company don’t have to pay managers for leftover vacation time in the following year."
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Product Sourcing"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Overall performance in designing, building, and maintaining our social media presence"
              : department == "project coordination"
              ? projectCoordinatorData[2].Header
              : "Planning & Strategic Thinking"}
          </FormLabel>
          <NumberInput
            value={ownership}
            defaultValue={0.0}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setownership(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="behaviour" top={"20px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Performance management processes"
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Amazon PPC Expertise"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Performance in terms of providing Sales Qualified Leads (SQL)"
              : department == "project coordination"
              ? projectCoordinatorData[3].Header
              : "Problem Solving & Decision Making"}{" "}
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={behaviour}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setbehaviour(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="TeamWord" top={"25px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Recruitment: Recruitment by preparing job descriptions posting ads and managing the hiring process · Quality candidates · Interviewing skills · Interview frequencies · Time taken Timely updates to management"
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Optimization of PPC"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Maintaining the Cost per lead (CPL) to efficiently utilize the marketing budget"
              : department == "project coordination"
              ? projectCoordinatorData[4].Header
              : "Technical & Conceptual"}
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={teamwork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setteamwork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Onboarding & ongoing training and development plans: Onboarding for new employees (include sharing the company mission, vision, and values, in addition to internal policies and procedures.) Professional development through training, seminars, workshops, and certifications. Have a formal meeting with each new employee and go over all the benefits (vacation, bonuses (eid, quarterly profit sharing etc)) Training Development:"
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Product Keyword Research"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Measuring and reporting the performance of all digital marketing campaigns, and assessing against goals (ROI and KPIs set by management)"
              : department == "project coordination"
              ? projectCoordinatorData[5].Header
              : "Communication & Motivation"}
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={qualitywork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setqualitywork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Policy Management: Implementation · Modifying if needed"
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Product Listing"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Team management and catering to their queries"
              : department == "project coordination"
              ? projectCoordinatorData[6].Header
              : "Leadership & Mentoring"}
          </FormLabel>
          <NumberInput
            value={timelydeliver}
            defaultValue={0.0}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => settimelydeliver(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            {selectedMember == "6347026485d8e33605a9df27"
              ? "Platform,Documentation/Drive Management: (FlowHCM, Linkedin, Calendar etc.), review HR drive on quarterly basis and archive/delete all old documents"
              : selectedMember == "63c6c392c071aaa8f6b3d9d9"
              ? "Order Management Efficiency"
              : selectedMember == "63ab608218db7a3fe9b6504c"
              ? "Identification of trends and insights, and optimize spend and performance based on the insights"
              : department == "project coordination"
              ? projectCoordinatorData[7].Header
              : "Reporting & Updates"}
          </FormLabel>
          <NumberInput
            value={overall}
            defaultValue={0.0}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setoverall(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="goal" top={"30px"}>
          <FormLabel>
            Interpersonal Skills ( Effective Communication , TeamWork, Good
            Behaviour, Emotional Intelligence, Self Management)
          </FormLabel>
          <NumberInput
            value={goal}
            defaultValue={0.0}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setGoal(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired top={"40px"}>
          <FormLabel>Feedback</FormLabel>

          <Textarea
            placeholder="Your Answer"
            isRequired={true}
            type="text"
            color={"white"}
            multiple
            value={descriptionbox}
            onChange={(event) => setDescriptionBox(event.currentTarget.value)}
          />
        </FormControl>

        <Button
          isDisabled={loading == true ? true : false}
          gap="20px"
          top={"70px"}
          variant="brand"
          type="submit"
          backgroundColor={"brand.500"}
        >
          {loading == true ? "Loading..." : "Upload KPI"}
        </Button>
      </form>
    </Box>
  );
}
