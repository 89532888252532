import React from "react";

// Chakra imports
import { Box, Flex, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("white", "#ff0052");

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h="2px" w="175px" my="10px" color={logoColor} /> */}
      {logoColor == "white" ? (
        <Image
          src={require("../../../assets/img/avatars/Vertical-Logo.png")}
          alt="Dan Abramov"
          marginBottom={"25px"}
        />
      ) : (
        <Image
          src={require("../../../assets/img/avatars/whiteFleekbizcopy.png")}
          alt="Dan Abramov"
          marginBottom={"25px"}
        />
      )}
<div>

</div>
      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
}

export default SidebarBrand;
