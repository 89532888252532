import React, { Component } from "react";
import Chart from "react-apexcharts";

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [
        {
          name: "series-1",
          data: [
            "Adeel",
            "Bilal",
            "Owais",
            "Naeem",
            "Atta",
            "Archand",
            "Rizwan",
            "Irfan",
          ],
        },
      ],
      chartOptions: {
        chart: {
          id: "basic-bar",
        },
        yaxis: {
          show: true,
          showAlways: true,
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        },
      },
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
