import React, { useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
// Custom components

// Assets
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import { CurrentMonth } from "constant/currentMonth";
import { parse } from "stylis";

export default function DevelopmentForm({ depart, setSelectedMemeber1,setSelectedMonth }) {
  const [data, setData] = React.useState([]);
  const [selectedMember, setSelectedMemeber] = React.useState("");
  const [selectManager, setSelectManger] = React.useState("development");
  const [TeamData, setTeamData] = React.useState([]);
  const [communication, setcommunication] = React.useState("0");
  const [behaviour, setbehaviour] = React.useState("0");
  const [ownership, setownership] = React.useState("0");
  const [teamwork, setteamwork] = React.useState("0");
  const [qualitywork, setqualitywork] = React.useState("0");
  const [timelydeliver, settimelydeliver] = React.useState("0");
  const [overall, setoverall] = React.useState(0);
  const [descriptionbox, setDescriptionBox] = React.useState("Nice Work");
  const [loading, setLoading] = React.useState(false);
  const [departmentTable, setDepartment] = React.useState("");

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.toLowerCase() !== "naruto") {
      error = "Jeez! You're not a fan 😱";
    }
    return error;
  }
  const form = useRef();
  const loginData = localStorage.getItem("login");
  const ProfileId = JSON.parse(loginData);
  // const ProfileId = useSelector((store) => store.counter.profile);
  const parse = (val) => val.replace(/^\$/, "");
  //Team Data
  React.useEffect(() => {
    ProfileId.department == "Director" || ProfileId.department == "HR"
      ? fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            var newArray = response.filter(function (el) {
              return el.compliance == false;
            });
            setData(newArray);
            setSelectedMemeber(response[0]._id);
          })
          .catch((err) => {
            console.log(err);
          })
      : fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
          .then((resp) => resp.json())
          .then((response) => {
            setData(response);
            setSelectedMemeber(response[0]._id);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);
  React.useEffect(() => {
    setSelectedMemeber1(selectedMember);
  }, [selectedMember]);
  useEffect(() => {
    //For Team
    fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
      .then((resp) => resp.json())
      .then((response) => {
        setTeamData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [depart]);
  const overallValue =
    Number(communication) +
    Number(behaviour) +
    Number(ownership) +
    Number(teamwork) +
    Number(qualitywork) +
    Number(timelydeliver);
  //For Submit Kpi
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const CurrentMonth1 = months[new Date().getMonth()];
  const NextMonth = months[CurrentMonth == 'january' ? 11:new Date().getMonth() - 1];
  const [month, setMonth] = React.useState(NextMonth);
  const [goal, setGoal] = React.useState("0");
  useEffect(() => {
    setSelectedMonth(month)
  },[month])
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const bodyObject = {
      teamId: selectedMember,
      name: "Muhammad",
      managerId: ProfileId._id,
      month: month,
      communication: communication,
      behaviour: behaviour,
      ownership: ownership,
      department: depart,
      manager: false,
      teamwork: teamwork,
      qualitywork: qualitywork,
      timelydeliver: timelydeliver,
      overall: overall,
      descriptionbox: descriptionbox,
      goals: goal,
    };
    fetch(`${baseURL}api/kpi/addkpi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        alert(response.message, "response");
        setcommunication("0");
        setbehaviour("0");
        setownership("0");
        setteamwork("0");
        setqualitywork("0");
        settimelydeliver("0");
        setoverall("0");
        setDescriptionBox('')
        setSelectedMemeber("");
        setGoal("0");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <Box pt={{ base: "180px", md: "180px", xl: "10px" }}>
      <form ref={form} onSubmit={handleSubmit}>
        <FormControl id="teammember" isRequired>
          <FormLabel>Select team member name</FormLabel>
          <Select
            placeholder="Select"
            value={selectedMember}
            onChange={(event) => setSelectedMemeber(event.currentTarget.value)}
          >
            {data.map((item) => {
              return <option value={item._id}>{item.name}</option>;
            })}
          </Select>
        </FormControl>
        <FormControl id="Month" isRequired>
          <FormLabel>Select Month</FormLabel>
          <Select onChange={(event) => setMonth(event.currentTarget.value)}>
          <option value={NextMonth}>{NextMonth.charAt(0).toUpperCase() + NextMonth.slice(1)}</option>
          <option value={CurrentMonth}>{CurrentMonth.charAt(0).toUpperCase() + CurrentMonth.slice(1)}</option>
          </Select>
        </FormControl>
        <FormControl isRequired id="Communication" top={"2px"}>
          <FormLabel>Techinal expertise based on work experience.</FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={communication}
            max={5.0}
            min={2.0}
            step={0.5}
            onChange={(valueString) => setcommunication(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="ownership" top={"10px"}>
          <FormLabel>Understanding of project requirement.</FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={ownership}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setownership(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="behaviour" top={"20px"}>
          <FormLabel>
            Out of the box approach (eg: Working on new frameworks or custom
            requirement).
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={behaviour}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setbehaviour(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="TeamWord" top={"25px"}>
          <FormLabel>Pressure and workload management.</FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={teamwork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setteamwork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            Coding Standards (eg: Less use of plugins,outdated versions,
            scripting and grid systems).
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={qualitywork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setqualitywork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="timelyDeliver" top={"35px"}>
          <FormLabel>
            Meeting Timelines (Delivering projects on time Eg: basic home page
            timeline should be 2 to 3 days with responsive and QA issue's).
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={timelydeliver}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => settimelydeliver(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="timelyDeliver" top={"35px"}>
          <FormLabel>
            Mananging multiple projects at the same time without hurting any
            deadline.
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={overall}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setoverall(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="goal" top={"35px"}>
          <FormLabel>
            Interpersonal Skills ( Effective Communication , TeamWork, Good
            Behaviour, Emotional Intelligence, Self Management).
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={goal}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setGoal(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired top={"40px"}>
          <FormLabel>Feedback</FormLabel>
          <Textarea
            placeholder="Your Answer"
            isRequired={true}
            type="text"
            color={"white"}
            multiple
            value={descriptionbox}
            onChange={(event) => setDescriptionBox(event.currentTarget.value)}
          />
        </FormControl>

        <Button
          isDisabled={loading == true ? true : false}
          gap="20px"
          top={"70px"}
          variant="brand"
          type="submit"
          backgroundColor={"brand.500"}
        >
          {loading == true ? "Loading..." : "Upload KPI"}
        </Button>
      </form>
    </Box>
  );
}
