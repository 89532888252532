import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { baseURL } from "constant/api";
import React, { useState } from "react";
import {
  MdAddTask,
  MdAdjust,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import { useSelector } from "react-redux";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import DevelopmentTable from "../dataTables/components/DevelopmentTable";
import { columnsDataDevelopment } from "../dataTables/variables/columnsData";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import Banner from "../profile/components/Banner";
import { CurrentMonth } from "constant/currentMonth";
import Card from "components/card/Card";
import UpdateKpi from "./components/UpdateKpi";
import DevelopmentTableQuarterly from "../quarterly/components/DevelopmentTablequarterly";
import {
  AssistantAccountData,
  AssitantItData,
  ContentWritterData,
  DesignData,
  developmentsData,
  DigitalData,
  ECommerceData,
  FinanceData,
  HumanResourcesData,
  ItManagerData,
  operationData,
  projectCoordinatorData,
  QaData,
  salesData,
  seoData,
} from "../quarterly/variables/columnsData";

export default function UserReports() {
  const [AllData, setData] = React.useState([]);
  const [jobspecific2, setJobSpecific2] = React.useState(0);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  // const Profile = useSelector((store) => store.counter.profile);
  // SelectProfile
  const loginData = localStorage.getItem("SelectProfile");
  const Profile = JSON.parse(loginData);
  // console.log(Profile1, "Profile");
  // const Profile = useSelector((store) => store.counter.selectProfile);
  const [updateTrue, setUpdateTrue] = useState(false);
  // console.log(Profile1,'checkSelect');
  React.useEffect(() => {
    fetch(`${baseURL}api/kpi/par_kpi?id=${Profile._id}`)
      .then((resp) => resp.json())
      .then((response) => setData(response))
      .catch((err) => console.log(err));
    //For Quarterly
    fetch(
      `${baseURL}api/jobspecific/getquarterlyperfomance?id=${Profile._id}&month=${CurrentMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        if (response == []) {
          // setJobSpecific(0);
        } else {
          setJobSpecific2(response?.JobSpecific);
        }
      })
      .catch((err) => console.log(err));
  }, [updateTrue]);
  // console.log(AllData[0].overall);
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const Current_Kpi =
    AllData[0]?.communication +
    AllData[0]?.behaviour +
    AllData[0]?.ownership +
    AllData[0]?.teamwork +
    AllData[0]?.qualitywork +
    AllData[0]?.timelydeliver +
    AllData[0]?.overall +
    AllData[0]?.goals;
  const SecondMonth =
    AllData[1]?.communication +
    AllData[1]?.behaviour +
    AllData[1]?.ownership +
    AllData[1]?.teamwork +
    AllData[1]?.qualitywork +
    AllData[1]?.timelydeliver +
    AllData[1]?.overall +
    AllData[1]?.goals;
  const ThirdMonth =
    AllData[2]?.communication +
    AllData[2]?.behaviour +
    AllData[2]?.ownership +
    AllData[2]?.teamwork +
    AllData[2]?.qualitywork +
    AllData[2]?.timelydeliver +
    AllData[2]?.overall +
    AllData[2]?.goals;
  const Average = Current_Kpi / 8 + SecondMonth / 8 + ThirdMonth / 8;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 5, md: 5, lg: 3, "2xl": 5 }}
        gap="20px"
        mb="20px"
      >
        {AllData.length > 2 ? (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={`Quarterly Kpi (${
              AllData[0].month.charAt(0).toUpperCase() +
              AllData[0].month.slice(1)
            }, ${
              AllData[1].month.charAt(0).toUpperCase() +
              AllData[1].month.slice(1)
            }, ${
              AllData[2].month.charAt(0).toUpperCase() +
              AllData[2].month.slice(1)
            })`}
            value={Number(Average).toFixed(2)}
          />
        ) : null}
        {AllData.length > 5 ? (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={`Quarterly Kpi (${AllData[3].month}, ${AllData[4].month}, ${AllData[5].month})`}
            value={Number(Average).toFixed(2)}
          />
        ) : null}

        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdAdjust} color={brandColor} />}
            />
          }
          name="Quarterly Kpi"
          value={jobspecific2}
        /> */}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <TotalSpent /> */}
        <Card mb={{ base: "0px", lg: "20px" }} align="center">
          <Box bgSize="cover" borderRadius="16px" h="131px" w="100%" />
          <Avatar
            mx="auto"
            src={Profile?.profilePic}
            h="87px"
            w="87px"
            mt="-43px"
            border="4px solid"
            borderColor={borderColor}
          />
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="xl"
            mt="10px"
          >
            {Profile?.name}
          </Text>
          <Text color={textColorSecondary} fontSize="sm">
            {Profile?.role}
          </Text>
          <Flex
            marginTop={"20px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex align="center" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {/* {AllData[0]?.month != CurrentMonth
                  ? Number(SecondMonth / 8).toFixed(2)
                  : Number(Current_Kpi / 8).toFixed(2)} */}
                {Number(
                  (AllData[AllData.length - 1]?.communication +
                    AllData[AllData.length - 1]?.behaviour +
                    AllData[AllData.length - 1]?.ownership +
                    AllData[AllData.length - 1]?.teamwork +
                    AllData[AllData.length - 1]?.qualitywork +
                    AllData[AllData.length - 1]?.timelydeliver +
                    AllData[AllData.length - 1]?.overall +
                    AllData[AllData.length - 1]?.goals) /
                    8
                ).toFixed(2)}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                My Current KPI
              </Text>
            </Flex>
          </Flex>
        </Card>
        {/* <WeeklyRevenue name={Profile?.name} /> */}
        <TotalSpent teamId={Profile._id} setUpdate={updateTrue} />
      </SimpleGrid>
      <Box columns={{ base: 1, md: 2, xl: 2 }}>
        {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
        <DevelopmentTableQuarterly
          columnsData={
            Profile.manager == true
              ? Profile.department == "E-commerce"
              ? ECommerceData:ItManagerData
              : Profile.department == "development"
              ? developmentsData
              : Profile.department == "seo"
              ? seoData
              : Profile.department == "quality assurance"
              ? QaData
              : Profile.department == "sales"
              ? salesData
              : Profile.department == "design_night" ||
                Profile.department == "design" ||
                Profile.department == "brand"
              ? Profile?.name == "Zeeshan Kiani"
                ? DigitalData
                : DesignData
              : Profile.department == "operation"
              ? operationData
              : Profile.department == "project coordination"
              ? projectCoordinatorData
              : Profile.department == "content writter"
              ? ContentWritterData
              : Profile.department == "finance"
              ? AssistantAccountData
              : Profile.department == "assistant accountant"
              ? AssistantAccountData
              : Profile.department == "managers"
              ? ItManagerData
              : Profile.department == "assistant it"
              ? AssitantItData
              : Profile.department == "human resources"
              ? HumanResourcesData
              : Profile.department == "E-commerce"
              ? ECommerceData
              : operationData
          }
          tableData={AllData}
          setUpdate={setUpdateTrue}
          update={updateTrue}
        />

        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        {/* <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}
        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
        {/* </SimpleGrid> */}
      </Box>
    </Box>
  );
}
