import React, { useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
// Custom components

// Assets
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import { CurrentMonth } from "constant/currentMonth";
import { parse } from "stylis";

export default function HumanResourcesForm({ depart, setSelectedMemeber1 ,setSelectedMonth}) {
  const [data, setData] = React.useState([]);
  const [selectedMember, setSelectedMemeber] = React.useState("");
  const [selectManager, setSelectManger] = React.useState("development");
  const [TeamData, setTeamData] = React.useState([]);
  const [communication, setcommunication] = React.useState("0");
  const [behaviour, setbehaviour] = React.useState("0");
  const [ownership, setownership] = React.useState("0");
  const [teamwork, setteamwork] = React.useState("0");
  const [qualitywork, setqualitywork] = React.useState("0");
  const [timelydeliver, settimelydeliver] = React.useState("0");
  const [overall, setoverall] = React.useState(0);
  const [descriptionbox, setDescriptionBox] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [departmentTable, setDepartment] = React.useState("");

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.toLowerCase() !== "naruto") {
      error = "Jeez! You're not a fan 😱";
    }
    return error;
  }
  const form = useRef();
  const loginData = localStorage.getItem("login");
  const ProfileId = JSON.parse(loginData);
  // const ProfileId = useSelector((store) => store.counter.profile);
  const parse = (val) => val.replace(/^\$/, "");
  //Team Data
  React.useEffect(() => {
    ProfileId.department == "Director" || ProfileId.department == "HR"
      ? fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            var newArray = response.filter(function (el) {
              return el.compliance == false;
            });
            setData(newArray);
            setSelectedMemeber(response[0]._id);
          })
          .catch((err) => {
            console.log(err);
          })
      : fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
          .then((resp) => resp.json())
          .then((response) => {
            setSelectedMemeber(response[0]._id);
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);
  React.useEffect(() => {
    setSelectedMemeber1(selectedMember);
  }, [selectedMember]);
  useEffect(() => {
    //For Team
    fetch(`${baseURL}api/auth/team?department=${depart}&manager=false`)
      .then((resp) => resp.json())
      .then((response) => {
        setTeamData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [depart]);
  const overallValue =
    Number(communication) +
    Number(behaviour) +
    Number(ownership) +
    Number(teamwork) +
    Number(qualitywork) +
    Number(timelydeliver);
  //For Submit Kpi
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const CurrentMonth1 = months[new Date().getMonth()];
  const NextMonth = months[CurrentMonth == 'january' ? 11:new Date().getMonth() - 1];
  const [month, setMonth] = React.useState(NextMonth);
  const [goal, setGoal] = React.useState("0");

  useEffect(() => {
    setSelectedMonth(month)
  },[month])

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const bodyObject = {
      teamId: selectedMember,
      name: "Muhammad",
      managerId: ProfileId._id,
      month: month,
      communication: communication,
      behaviour: behaviour,
      ownership: ownership,
      teamwork: teamwork,
      qualitywork: qualitywork,
      timelydeliver: timelydeliver,
      overall: overall,
      descriptionbox: descriptionbox,
      department: depart,
      goals: goal,
      manager: false,
    };
    fetch(`${baseURL}api/kpi/addkpi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        alert(response.message, "response");
        setcommunication("0");
        setbehaviour("0");
        setownership("0");
        setteamwork("0");
        setqualitywork("0");
        setownership("0");
        settimelydeliver("0");
        setoverall("0");
        setDescriptionBox('')
        setGoal("0");
        setSelectedMemeber("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <Box pt={{ base: "180px", md: "180px", xl: "10px" }}>
      <form ref={form} onSubmit={handleSubmit}>
        <FormControl id="teammember" isRequired>
          <FormLabel>Select team member name</FormLabel>
          <Select
            placeholder="Select"
            value={selectedMember}
            onChange={(event) => setSelectedMemeber(event.currentTarget.value)}
          >
            {data.map((item) => {
              return <option value={item._id}>{item.name}</option>;
            })}

            {/* <option>Archand</option>
              <option>Adeel</option>
              <option>Anas</option>
              <option>Atta Mehdi</option>
              <option>Irfan</option>
              <option>Rizwan</option>
              <option>Usama</option>
              <option>Naeem</option>
              <option>Naeem Prasla</option>
              <option>Faisal Ahmed</option> */}
          </Select>
        </FormControl>
        <FormControl id="Month" isRequired>
          <FormLabel>Select Month</FormLabel>
          <Select onChange={(event) => setMonth(event.currentTarget.value)}>
          <option value={NextMonth}>{NextMonth.charAt(0).toUpperCase() + NextMonth.slice(1)}</option>
          <option value={CurrentMonth}>{CurrentMonth.charAt(0).toUpperCase() + CurrentMonth.slice(1)}</option>
          </Select>
        </FormControl>
        <FormControl isRequired id="Communication" top={"2px"}>
          <FormLabel>
            Compensation & Benefit management: Health insurance OPD · Gifts
            (Marriage/ Birth of baby) · SESSI · EOBI etc .
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={communication}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setcommunication(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="ownership" top={"10px"}>
          <FormLabel>
            Attendance/Payroll Complete all payroll activities by 25th of each
            month Keep track of all employee attendance and update managers on
            weekly basis Encourage employees to take vacations and time off on a
            regular basis specially managers so company don’t have to pay
            managers for leftover vacation time in the following year.
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={ownership}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setownership(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="behaviour" top={"20px"}>
          <FormLabel>Performance management processes</FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={behaviour}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setbehaviour(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="TeamWord" top={"25px"}>
          <FormLabel>
            Recruitment: Recruitment by preparing job descriptions posting ads
            and managing the hiring process · Quality candidates · Interviewing
            skills · Interview frequencies · Time taken Timely updates to
            management
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={teamwork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setteamwork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            Onboarding & ongoing training and development plans: Onboarding for
            new employees (include sharing the company mission, vision, and
            values, in addition to internal policies and procedures.) ·
            Professional development through training, seminars, workshops, and
            certifications. Have a formal meeting with each new employee and go
            over all the benefits (vacation, bonuses (eid, quarterly profit
            sharing etc)) Training Development:
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={qualitywork}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setqualitywork(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            Policy Management: Implementation · Modifying if needed
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={timelydeliver}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => settimelydeliver(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl isRequired id="qualitywork" top={"30px"}>
          <FormLabel>
            Platform,Documentation/Drive Management: (FlowHCM, Linkedin,
            Calendar etc.), review HR drive on quarterly basis and
            archive/delete all old documents
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={overall}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setoverall(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired id="goal" top={"35px"}>
          <FormLabel>
            Interpersonal Skills ( Effective Communication , TeamWork, Good
            Behaviour, Emotional Intelligence, Self Management).
          </FormLabel>
          <NumberInput
            defaultValue={0.0}
            value={goal}
            max={5.0}
            min={1.0}
            step={0.5}
            onChange={(valueString) => setGoal(parse(valueString))}
          >
            <NumberInputField color={textColor} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired top={"40px"}>
          <FormLabel>
            Feedback
          </FormLabel>
          <Textarea
            placeholder="Your Answer"
            isRequired={true}
            type="text"
            color={"white"}
            multiple
            value={descriptionbox}
            onChange={(event) => setDescriptionBox(event.currentTarget.value)}
          />
        </FormControl>

        <Button
          isDisabled={loading == true ? true : false}
          gap="20px"
          top={"70px"}
          variant="brand"
          type="submit"
          backgroundColor={"brand.500"}
        >
          {loading == true ? "Loading..." : "Upload KPI"}
        </Button>
      </form>
    </Box>
  );
}
