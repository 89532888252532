import React from "react";
import {
  Box,
  Button,
  Flex,
  GrclassName,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Grid,
  Avatar,
  Textarea,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import { CurrentMonth } from "constant/currentMonth";
const UpdateKpi = (props) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  //For Submit Kpi

  const [data, setData] = React.useState([]);
  const [selectedMember, setSelectedMemeber] = React.useState();
  const [selectManager, setSelectManger] = React.useState("development");
  const [TeamData, setTeamData] = React.useState([]);
  const [communication, setcommunication] = React.useState(
    props.dataUpdate.communication
  );
  const [behaviour, setbehaviour] = React.useState(props.dataUpdate.behaviour);
  const [ownership, setownership] = React.useState(props.dataUpdate.ownership);
  const [teamwork, setteamwork] = React.useState(props.dataUpdate.teamwork);
  const [qualitywork, setqualitywork] = React.useState(
    props.dataUpdate.qualitywork
  );
  const [timelydeliver, settimelydeliver] = React.useState(
    props.dataUpdate.timelydeliver
  );
  const [overall, setoverall] = React.useState(props.dataUpdate.overall);
  const [goal, setGoal] = React.useState(props.dataUpdate.goals);
  const [descriptionbox, setDescriptionBox] = React.useState(
    props.dataUpdate.descriptionbox
  );
  const [loading, setLoading] = React.useState(false);
  const [departmentTable, setDepartment] = React.useState("");
  const [particularMemberKpi, setParticularMonthKpi] = React.useState([]);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.toLowerCase() !== "naruto") {
      error = "Jeez! You're not a fan 😱";
    }
    return error;
  }
  const form = React.useRef();
  const loginData = localStorage.getItem("login");
  const ProfileId = JSON.parse(loginData);
  // const ProfileId = useSelector((store) => store.counter.profile);

  const parse = (val) => val.replace(/^\$/, "");

  //Handle Submit
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const bodyObject = {
      communication: communication,
      behaviour: behaviour,
      ownership: ownership,
      teamwork: teamwork,
      qualitywork: qualitywork,
      timelydeliver: timelydeliver,
      overall: overall,
      descriptionbox: descriptionbox,
      goals: goal,
    };
    fetch(`${baseURL}api/kpi/update-kpi?id=${props.dataUpdate._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        alert(response.message, "response");
        props.setUpdate(!props.update);
        setSelectManger(selectManager);
        props.setEdit(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div>
      <Flex
        mt="45px"
        mb="20px"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}
      >
        <Text color={textColor} fontSize="2xl" fontWeight="700">
          Update Team KPI
        </Text>
      </Flex>
      <Box marginBottom={"5%"}>
        <form ref={form} onSubmit={handleSubmit}>
          <FormControl isRequired id="Communication" top={"5px"}>
            <FormLabel>{props?.header[1]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0.0}
              value={communication}
              max={5.0}
              min={1.0}
              step={0.5}
              onChange={(valueString) => setcommunication(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl isRequired id="ownership" top={"10px"}>
            <FormLabel>{props?.header[2]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              value={ownership}
              max={5.0}
              min={1.0}
              step={0.5}
              onChange={(valueString) => setownership(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          {/* <FormControl isRequired id="punctuality" top={"15px"}>
            <FormLabel>Punctuality</FormLabel>
            <NumberInput defaultValue={0} max={5.0} min={1.0} step={0.5}>
              <NumberInputField color={"white"} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl> */}
          <FormControl isRequired id="behaviour" top={"20px"}>
            <FormLabel>{props?.header[3]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              value={behaviour}
              max={5.0}
              min={1.0}
              step={0.5}
              onChange={(valueString) => setbehaviour(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl isRequired id="TeamWord" top={"25px"}>
            <FormLabel>{props?.header[4]?.Header}</FormLabel>
            <NumberInput
              defaultValue={0}
              allowMouseWheel={true}
              value={teamwork}
              max={5.0}
              min={1.0}
              step={0.5}
              onChange={(valueString) => setteamwork(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl isRequired id="qualitywork" top={"30px"}>
            <FormLabel>{props?.header[5]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              value={qualitywork}
              max={5.0}
              min={1.0}
              step={0.5}
              onChange={(valueString) => setqualitywork(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl isRequired id="timelyDeliver" top={"35px"}>
            <FormLabel>{props?.header[6]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              max={5.0}
              min={1.0}
              step={0.5}
              value={timelydeliver}
              onChange={(valueString) => settimelydeliver(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl isRequired id="timelyDeliver" top={"35px"}>
            <FormLabel>{props?.header[7]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              max={5.0}
              min={1.0}
              step={0.5}
              value={overall}
              onChange={(valueString) => setoverall(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl isRequired id="timelyDeliver" top={"35px"}>
            <FormLabel>{props?.header[8]?.Header}</FormLabel>
            <NumberInput
              allowMouseWheel={true}
              defaultValue={0}
              max={5.0}
              min={1.0}
              step={0.5}
              value={goal}
              onChange={(valueString) => setGoal(parse(valueString))}
            >
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          {/* <FormControl id="goalsTraining" top={"50px"}>
            <FormLabel>
              Goals/ Training & Development (To be Filled quarterly) <br />
              Goals status I Attending training sessions! implementation of new
              learning
            </FormLabel>
            <NumberInput defaultValue={2.5} max={5.0} min={1.0} step={0.5}>
              <NumberInputField color={textColor} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl top={"55px"}>
            <FormLabel>
              Descriptive feedback (To be filled quarterly){" "}
            </FormLabel>
            <Input placeholder="Your Answer" type="text" />
          </FormControl> */}
          <FormControl isRequired top={"60px"}>
            <FormLabel>Feedback</FormLabel>
            <Textarea
              placeholder="Your Answer"
              isRequired={true}
              type="text"
              color={"white"}
              multiple
              value={descriptionbox}
              onChange={(event) => setDescriptionBox(event.currentTarget.value)}
            />
          </FormControl>
          <Button
            isDisabled={loading == true ? true : false}
            gap="20px"
            top={"70px"}
            variant="brand"
            type="submit"
          >
            {loading == true ? "Loading..." : "Update KPI"}
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default UpdateKpi;
