import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import CheckTable from "views/admin/dataTables/components/CheckTable";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React from "react";
import { useSelector } from "react-redux";
import { baseURL } from "constant/api";
import DevelopmentTableQuarterly from "./components/DevelopmentTablequarterly";
import {
  AssistantAccountData,
  AssitantItData,
  ContentWritterData,
  DesignData,
  developmentsData,
  DigitalData,
  FinanceData,
  HumanResourcesData,
  ItManagerData,
  operationData,
  projectCoordinatorData,
  QaData,
  salesData,
  seoData,
} from "./variables/columnsData";

export default function Quarterly() {
  // Chakra Color Mode
  const [AllData, setData] = React.useState([]);

  const loginData = localStorage.getItem("login");
  const Profile = JSON.parse(loginData);
  // const Profile = useSelector((store) => store.counter.profile);

  React.useEffect(() => {
    fetch(`${baseURL}api/kpi/par_kpi?id=${Profile._id}`)
      .then((resp) => resp.json())
      .then((response) => setData(response))
      .catch((err) => console.log(err));
  }, []);
  // React.useEffect(() => {
  //   fetch(`${baseURL}api/kpi/par_kpi?id=${Profile._id}`)
  //     .then((resp) => resp.json())
  //     .then((response) => setData(response))
  //     .catch((err) => console.log(err));
  // }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
      <DevelopmentTableQuarterly
        columnsData={
          Profile.manager == true
            ? ItManagerData
            : Profile.department == "development"
            ? developmentsData
            : Profile.manager == true
            ? ItManagerData
            : Profile.department == "seo"
            ? seoData
            : Profile.department == "quality assurance"
            ? QaData
            : Profile.department == "sales"
            ? salesData
            : Profile.department == "design_night" ||
              Profile.department == "design" ||
              Profile.department == "brand"
            ? Profile._id == "63ab608218db7a3fe9b6504c"
              ? DigitalData
              : DesignData
            : Profile.department == "operation"
            ? operationData
            : Profile.department == "project coordination"
            ? projectCoordinatorData
            : Profile.department == "content writter"
            ? ContentWritterData
            : Profile.department == "finance"
            ? AssistantAccountData
            : Profile.department == "assistant accountant"
            ? AssistantAccountData
            : Profile.department == "dassistant it"
            ? AssitantItData
            : Profile.department == "human resources"
            ? HumanResourcesData
            : operationData
        }
        tableData={AllData}
      />
      {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
      {/* <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}
      {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
      {/* </SimpleGrid> */}
    </Box>
  );
}
