// Chakra imports
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import "./default.css";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <div className="mainContainer">
      <Flex position="relative">
        <Flex
          h={{
            sm: "initial",
            md: "unset",
            lg: "100vh",
            xl: "97vh",
          }}
          w="550px"
          // maxW={{ md: "66%", lg: "1313px" }}
          mx="auto"
          pt={{ sm: "50px", md: "0px" }}
          px={{ lg: "30px", xl: "0px" }}
          ps={{ xl: "70px" }}
          justifyContent="start"
          direction="column"
        >
          <NavLink
            to="/admin"
            style={() => ({
              width: "fit-content",
              marginTop: "40px",
            })}
          >
            <Flex
              align="center"
              ps={{ base: "25px", lg: "0px" }}
              pt={{ lg: "0px", xl: "0px" }}
              w="fit-content"
            >
              {/* <Text ms="0px" fontSize="sm" color="secondaryGray.600">
                Back to Dashboard
              </Text> */}
            </Flex>
          </NavLink>

          {children}

          <Box
            display={{ base: "none", md: "block" }}
            h="100%"
            minH="100vh"
            w={{ lg: "50vw", "2xl": "44vw" }}
            position="absolute"
            right="0px"
          >
            <Flex
              bg={`url(${illustrationBackground})`}
              justify="center"
              align="end"
              w="100%"
              h="100%"
              bgSize="cover"
              bgPosition="50%"
              position="absolute"
              borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
            ></Flex>
          </Box>
        </Flex>
        <FixedPlugin />
      </Flex>
      <div className="bg-image"></div>
      <div className="bg-text">
        <img
          src={require("../../assets/img/auth/Vertical-Logo2.png")}
          alt="Avatar"
          className="avatar"
        />
      </div>
    </div>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
