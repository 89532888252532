import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import "./styles.css";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { baseURL } from "constant/api";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "redux/reducer";
import { ChartData } from "redux/reducer";
import { TeamPerfomance } from "redux/reducer";
import { TeamPerfomanceName } from "redux/reducer";
import { CurrentMonth } from "constant/currentMonth";

function Register({ stateChange }) {
  //For Dispatch in Redux
  const dispatch = useDispatch();

  // Chakra color mode
  const textColor = useColorModeValue("#ff0052", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("#ff0052", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("#ff0052", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [allKpiData, setAllKpiData] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [manager, setManager] = useState(false);
  const [admin, setAdmin] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();
  const loginData = localStorage.getItem("login");
  const ProfileCheck = JSON.parse(loginData);
  // const ProfileCheck = useSelector((store) => store.counter.profile);
  // useEffect(() => {
  //   window.location.reload(false);
  // }, []);
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const bodyObject = {
      name: name,
      email: email,
      password: password,
      role: designation,
      department: department,
      manager: manager,
      profilePic:
        "https://wpimg.pixelied.com/blog/wp-content/uploads/2021/06/15175913/Colors-in-Profile-Pictures.jpeg",
      isAdmin: admin,
    };
    fetch(`${baseURL}api/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        // setLoading(false);
        setData(response);
        stateChange(true);
        alert("User Successfully Registered");
        dispatch(Profile(response));
      })
      .catch((err) => {
        setLoading(false);
        stateChange(true);
        console.log(err);
      });
  };

  return (
    <>
      <div className="registerText">
        <a onClick={() => stateChange(false)} className="link">
          X
        </a>
      </div>
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          Add Member
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Please fill all the fields!
        </Text>
      </Box>

      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              bg={"white"}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              onChange={(event) => setName(event.currentTarget.value)}
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter your full name"
              color={"black"}
              mb="24px"
              fontWeight="500"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              color={"black"}
              bg={"white"}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              onChange={(event) => setEmail(event.currentTarget.value)}
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@simmmple.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Designation<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              color={"black"}
              bg={"white"}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              onChange={(event) => setDesignation(event.currentTarget.value)}
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Mobile App developer"
              mb="24px"
              fontWeight="500"
              size="lg"
            />

            <FormControl id="teammember" isRequired top={"-10px"}>
              <FormLabel>Select department</FormLabel>
              <Select
                bg={"white"}
                color="black"
                onChange={(event) => setDepartment(event.currentTarget.value)}
              >
                <option selected hidden disabled value="Department">
                  Department
                </option>
                <option value="sales">
                  {"sales".charAt(0).toUpperCase() + "sales".slice(1)}
                </option>
                <option value="development">
                  {"development".charAt(0).toUpperCase() +
                    "development".slice(1)}
                </option>
                <option value="seo">
                  {"seo".charAt(0).toUpperCase() + "seo".slice(1)}
                </option>
                <option value="quality assurance">
                  {"quality assurance".charAt(0).toUpperCase() +
                    "quality assurance".slice(1)}
                </option>
                <option value="design_night">
                  {"design night".charAt(0).toUpperCase() +
                    "design night".slice(1)}
                </option>
                <option value="design">
                  {"design".charAt(0).toUpperCase() + "design".slice(1)}
                </option>
                <option value={'operation'}>
                  {"operation".charAt(0).toUpperCase() + "operation".slice(1)}
                </option>
                <option value={"project coordination"}>
                  {"project coordination".charAt(0).toUpperCase() +
                    "project coordination".slice(1)}
                </option>
                <option value={"content writter"}>
                  {"content writter".charAt(0).toUpperCase() +
                    "content writter".slice(1)}
                </option>
                <option value={"finance"}>
                  {"finance".charAt(0).toUpperCase() + "finance".slice(1)}
                </option>
                <option value={"sales operation"}>
                  {"sales operation".charAt(0).toUpperCase() +
                    "sales operation".slice(1)}
                </option>
                <option value={"assistant accountant"}>
                  {"assistant accountant".charAt(0).toUpperCase() +
                    "assistant accountant".slice(1)}
                </option>
                <option value={"managers"}>
                  {"managers".charAt(0).toUpperCase() + "managers".slice(1)}
                </option>
                <option value={"assistant it"}>
                  {"assistant it".charAt(0).toUpperCase() +
                    "assistant it".slice(1)}
                </option>
                <option value={"brand"}>
                  {"brand".charAt(0).toUpperCase() + "brand".slice(1)}
                </option>
                <option value={"human resources"}>
                  {"human resources".charAt(0).toUpperCase() +
                    "human resources".slice(1)}
                </option>
              </Select>
            </FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                color={"black"}
                bg={"white"}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                onChange={(event) => setPassword(event.currentTarget.value)}
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <Stack spacing={5} direction="row">
                <Checkbox
                  isChecked={manager}
                  colorScheme="red"
                  defaultChecked
                  onChange={(e) => setManager(e.target.checked)}
                >
                  Manager
                </Checkbox>
                <Checkbox
                  isChecked={admin}
                  colorScheme="red"
                  defaultChecked
                  onChange={(e) => setAdmin(e.target.checked)}
                >
                  Admin
                </Checkbox>
              </Stack>
            </Flex>
            <Button
              isDisabled={loading == true ? true : false}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              bg={"#ff0052"}
              w="100%"
              h="50"
              mb="24px"
              type="submit"
            >
              {loading == true ? "Loading..." : "Register"}
            </Button>
          </FormControl>
        </form>
      </Flex>
    </>
  );
}

export default Register;
