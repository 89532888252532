/* eslint-disable */
import { DownloadIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAlert } from "react-alert";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import { baseURL } from "constant/api";
import { CurrentMonth } from "constant/currentMonth";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import UpdateKpi from "views/admin/rtl/components/UpdateKpi";
import { ItManagerData } from "../variables/columnsData";

export default function DevelopmentTableQuarterly(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [EditKpi, setEditKpi] = useState(false);
  const [SelectData, setSelectData] = useState();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  //For State hook to save the All Kpi

  const loginData = localStorage.getItem("login");
  const Profile = JSON.parse(loginData);
  // const Profile = useSelector((store) => store.counter.profile);

  const [emailSend, setEmailSend] = useState(false);
  // const alert = useAlert();
  const sendEmail = () => {
    const loginData = localStorage.getItem("SelectProfile");
    const Profile2 = JSON.parse(loginData);
    fetch(
      `${baseURL}api/kpi/email_sent_kpi?teamId=${Profile2?._id}&month=${CurrentMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        alert("Successfully send the Email");

        setEmailSend(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // alert(tableData[index1].month)

  return (
    <>
      {EditKpi == true ? (
        <UpdateKpi
          setEdit={setEditKpi}
          dataUpdate={SelectData}
          header={columnsData}
          setUpdate={props.setUpdate}
          update={props.update}
        />
      ) : (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "scroll" }}
        >
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              My Kpi's
            </Text>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index1) => {
                    return (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index1}
                        borderColor={borderColor}
                      >
                        {column.render("Header") == "Edit" ||
                        column.render("Header") == "Send Email" ? (
                          tableData[index]?.teamId == Profile?._id ? null : (
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "12px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header").slice(0, 30)}
                            </Flex>
                          )
                        ) : (
                          <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "12px", lg: "12px" }}
                            color="gray.400"
                          >
                            {column.render("Header").slice(0, 30)}
                          </Flex>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index1) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index1}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Month") {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {cell.value.charAt(0).toUpperCase() +
                              cell.value.slice(1)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[1].Header) {
                        data = (
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="1sm"
                              padding={"1"}
                            >
                              {Number(cell.value).toFixed(2)}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header == columnsData[2].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[3].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[4].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[5].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[6].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[7].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[8].Header) {
                        data = (
                          <Text color={textColor} fontSize="1sm">
                            {Number(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header == columnsData[9].Header) {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                              width={200}
                            >
                              {String(cell.value).slice(0, 50) + "..."}
                            </Text>
                            {/* <Progress
                          variant="table"
                          colorScheme="brandScheme"
                          h="8px"
                          w="63px"
                          value={cell.value}
                        /> */}
                          </Flex>
                        );
                      } else if (cell.column.Header == "Overall Kpi") {
                        data = (
                          <Flex align="center" alignItems={"center"}>
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {Number(
                                (tableData[index1].communication +
                                  tableData[index1].behaviour +
                                  tableData[index1].ownership +
                                  tableData[index1].teamwork +
                                  tableData[index1].qualitywork +
                                  tableData[index1].timelydeliver +
                                  tableData[index1].overall +
                                  tableData[index1].goals) /
                                  8
                              ).toFixed(2)}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header == "Send Email") {
                        data = (
                          <Flex align="center" alignItems={"center"}>
                            {tableData[index1].teamId ==
                              Profile._id ? null : tableData[index1].month == months[new Date().getMonth() - 1] ? (
                              // <EditIcon
                              //   cursor={"pointer"}
                              //   w={5}
                              //   h={5}
                              //   color="red.500"
                              //   onClick={() => {
                              //     setSelectData(tableData[index1]);
                              //     setEditKpi(true);
                              //   }}
                              // />
                              emailSend == true ? (
                                <Text color={textColor} fontSize="1sm">
                                  {"Email sended"}
                                </Text>
                              ) : (
                                <Image
                                  src={require("../../../../assets/img/avatars/send.png")}
                                  w={{ base: "150px" }}
                                  h={{ base: "30px" }}
                                  borderRadius="20px"
                                  cursor={"pointer"}
                                  onClick={() => sendEmail()}
                                />
                              )
                            ) : null}
                          </Flex>
                        );
                      } else if (cell.column.Header == "Edit") {
                        data = (
                          <Flex align="center" alignItems={"center"}>
                            {tableData[index1].teamId ==
                            Profile._id ? null : (tableData[index1].month ==
                              CurrentMonth || tableData[index1].month ==
                              months[CurrentMonth == 'january' ? 11: new Date().getMonth() - 1]) ? (
                              <EditIcon
                                cursor={"pointer"}
                                w={5}
                                h={5}
                                color="red.500"
                                onClick={() => {
                                  setSelectData(tableData[index1]);
                                  setEditKpi(true);
                                }}
                              />
                            ) : null}
                          </Flex>
                        );
                      } else if (cell.column.Header) {
                        const overall = Number(
                          (tableData[index1].communication +
                            tableData[index1].behaviour +
                            tableData[index1].ownership +
                            tableData[index1].teamwork +
                            tableData[index1].qualitywork +
                            tableData[index1].timelydeliver +
                            tableData[index1].overall +
                            tableData[index1].goals) /
                            8
                        ).toFixed(2);
                        data = (
                          <Flex align="center" alignItems={"center"}>
                            {/* <Progress
                      variant="table"
                      colorScheme="brandScheme"
                      h="8px"
                      w="63px"
                      value={cell.value}
                    /> */}
                            <CSVLink
                              data={[
                                [
                                  "Month",
                                  columnsData[1].Header,
                                  columnsData[2].Header,
                                  columnsData[3].Header,
                                  columnsData[4].Header,
                                  columnsData[5].Header,
                                  columnsData[6].Header,
                                  columnsData[7].Header,
                                  columnsData[8].Header,
                                  "Feedback",
                                  "Overall Kpi",
                                ],
                                [
                                  tableData[index1].month,
                                  tableData[index1].communication,
                                  tableData[index1].behaviour,
                                  tableData[index1].ownership,
                                  tableData[index1].teamwork,
                                  tableData[index1].qualitywork,
                                  tableData[index1].timelydeliver,
                                  tableData[index1].overall,
                                  tableData[index1].goals,
                                  tableData[index1].descriptionbox,
                                  overall,
                                ],
                              ]}
                            >
                              <DownloadIcon w={5} h={5} color="red.500" />
                            </CSVLink>
                          </Flex>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      )}
    </>
  );
}
