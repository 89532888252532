import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import "./styles.css";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { baseURL } from "constant/api";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "redux/reducer";
import { ChartData } from "redux/reducer";
import { TeamPerfomance } from "redux/reducer";
import { TeamPerfomanceName } from "redux/reducer";
import { CurrentMonth } from "constant/currentMonth";
import Register from "../Register";

function SignIn() {
  //For Dispatch in Redux
  const dispatch = useDispatch();

  // Chakra color mode
  const textColor = useColorModeValue("#ff0052", "white");
  const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [signIn, setSignIn] = useState(true);
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [allKpiData, setAllKpiData] = useState([]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();
  const ProfileCheck = useSelector((store) => store.counter.profile);
  // useEffect(() => {
  //   window.location.reload(false);
  // }, []);
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const bodyObject = {
      email: email,
      password: password,
      
    };

    fetch(`https://www.api.codpixels.com/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then(async (response) => {
        // setLoading(false);
        if (response.message) {
          alert(response.message);
        } else {
          setData(response);
          dispatch(Profile(response));
          const data = JSON.stringify(response);
          await localStorage.setItem("login", data);

          history.push("/admin/default");
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // console.log(ProfileCheck, "Profile CHeck");

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        // mx={{ base: "auto", lg: "0px" }}
        // me="auto"
        // h="80%"
        zIndex={1}
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
        // borderWidth={"10px"}
      >
        {signIn == true ? (
          <div className="left">
            <div>
              <Box me="auto">
                <Heading color={textColor} fontSize="36px" mb="10px">
                  Sign In
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColorSecondary}
                  fontWeight="400"
                  fontSize="md"
                >
                  Enter your email and password to sign in!
                </Text>
              </Box>
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: "auto", lg: "unset" }}
                me="auto"
                mb={{ base: "20px", md: "auto" }}
              >
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      borderColor={"black"}
                      fontSize="sm"
                      onChange={(event) => setEmail(event.currentTarget.value)}
                      ms={{ base: "0px", md: "0px" }}
                      type="email"
                      placeholder="mail@simmmple.com"
                      mb="24px"
                      fontWeight="500"
                      size="lg"
                    />
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        borderColor={"black"}
                        fontSize="sm"
                        placeholder="Min. 8 characters"
                        mb="24px"
                        onChange={(event) =>
                          setPassword(event.currentTarget.value)
                        }
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      mb="24px"
                    >
                      {/* <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </NavLink> */}
                    </Flex>
                    <Button
                      isDisabled={loading == true ? true : false}
                      fontSize="sm"
                      bg={"#ff0052"}
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mb="24px"
                      type="submit"
                    >
                      {loading == true ? "Loading..." : "Sign In"}
                    </Button>
                  </FormControl>
                </form>
              
              </Flex>
            </div>
          </div>
        ) : (
          <Register stateChange={setSignIn} />
        )}
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
