import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import CheckTable from "views/admin/dataTables/components/CheckTable";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
// import {
//   columnsDataDevelopment,
// } from "views/admin/AllTeam/components/ColumnData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React from "react";
import { useSelector } from "react-redux";
import { baseURL } from "constant/api";
import { columnsDataTeam } from "./components/ColumnData";
import AllTeamTable from "./components/AllTeamTable";

export default function AllTeams() {
  // Chakra Color Mode
  const [AllData, setData] = React.useState([]);

  const Profile = useSelector((store) => store.counter.profile);
  React.useEffect(() => {
    fetch(`${baseURL}api/auth/allteam`)
      .then((resp) => resp.json())
      .then((response) => {
        const data = [];
        response.map((item) => {
          item.department !== "ceo" && data.push(item);
        });
        // alert(response[0].department)
        setData(data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
      <AllTeamTable columnsData={columnsDataTeam} tableData={AllData} />
      {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
      {/* <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}
      {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
      {/* </SimpleGrid> */}
    </Box>
  );
}
