import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPersonAdd,
  MdTimer,
  MdStream,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import JobSpecific from "views/admin/jobSpecific";
import { useDispatch } from "react-redux";
import { ChartData } from "redux/reducer";
import { TeamPerfomance } from "redux/reducer";
import { TeamPerfomanceName } from "redux/reducer";
import Quarterly from "views/admin/quarterly";
import AllTeams from "views/admin/AllTeam";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/singleprofile",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Add team Kpi",
    layout: "/admin",
    path: "/team-kpi",
    icon: <Icon as={MdPersonAdd} width="20px" height="20px" color="inherit" />,
    component: NFTMarketplace,
    secondary: true,
  },
  // {
  //   name: "Job Specific",
  //   layout: "/admin",
  //   path: "/job-specific",
  //   icon: <Icon as={MdTimer} width="20px" height="20px" color="inherit" />,
  //   component: JobSpecific,
  //   secondary: true,
  // },
  // {
  //   name: "My Kpi's",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/my-kpi",
  //   component: DataTables,
  // },
  {
    name: "My KPI",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/my-quarterly",
    component: Quarterly,
  },
  // {
  //   name: "User",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "All Team",
    layout: "/admin",
    path: "/allteam",
    icon: <Icon as={MdStream} width="20px" height="20px" color="inherit" />,
    component: AllTeams,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
];

export default routes;
