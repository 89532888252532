// chakra imports
import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { CurrentMonth } from "constant/currentMonth";
import { useSelector } from "react-redux";
import { baseURL } from "constant/api";

export function ItemContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const [team, setTeam] = useState([]);
  const [kpi, setKpi] = useState([]);

  const loginData = localStorage.getItem("login");
  const ProfileCheck = JSON.parse(loginData);
  // const ProfileCheck = useSelector((store) => store.counter.profile);
  useEffect(() => {
    fetch(
      `${baseURL}api/auth/teamname?department=${
        ProfileCheck.department
      }&manager=${ProfileCheck.department == "Director" ? true : false}`
    )
      .then((resp) => resp.json())
      .then((response1) => {
        setTeam(response1);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(
      `${baseURL}api/kpi/all_kpi_mon?id=${ProfileCheck._id}&month=${CurrentMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        // setTeamKpi(response);
        setKpi(response);
        // dispatch(TeamPerfomanceName(response));
        // history.push("/admin/default");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Flex
        justify="center"
        align="center"
        borderRadius="16px"
        minH={{ base: "60px", md: "70px" }}
        h={{ base: "60px", md: "70px" }}
        minW={{ base: "60px", md: "70px" }}
        w={{ base: "60px", md: "70px" }}
        me="14px"
        bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      >
        <Icon as={MdUpgrade} color="white" w={8} h={14} />
      </Flex>
      {team.length == kpi.length ? (
        <Flex flexDirection="column">
          <Text
            mb="5px"
            fontWeight="bold"
            color={textColor}
            fontSize={{ base: "md", md: "md" }}
          >
            No Update: Thanks for UpToDate
          </Text>
          
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <Text
            mb="5px"
            fontWeight="bold"
            color={textColor}
            fontSize={{ base: "md", md: "md" }}
          >
            New Update: {String(CurrentMonth).toUpperCase()} KPI
          </Text>
          <Flex alignItems="center">
            <Text
              fontSize={{ base: "sm", md: "sm" }}
              lineHeight="100%"
              color={textColor}
            >
              Please Fill out your team's KPI form. <br />
              <br /> Note: If you fill in all the team KPIs so
              <br /> The Badge will be removed automatically
            </Text>
          </Flex>{" "}
        </Flex>
      )}
    </>
  );
}
