import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  GrclassName,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Grid,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import "./styles.css";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
// Custom components
import Banner from "views/admin/profile/components/Banner";
import TableTopCreators from "views/admin/marketplace/components/TableTopCreators";
import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
import { baseURL } from "constant/api";
import { useSelector } from "react-redux";
import { CurrentMonth } from "constant/currentMonth";
import { parse } from "stylis";
import { MdArrowBackIos } from "react-icons/md";
import SalesForm from "../jobSpecific/components/SalesForm";
import DevelopmentForm from "../jobSpecific/components/Development";
import SEOForm from "../jobSpecific/components/SEO";
import DesignForm from "../jobSpecific/components/Design";
import QualityAForm from "../jobSpecific/components/QualityA";
import OperationForm from "../jobSpecific/components/Operation";
import ProjectCoordinateForm from "../jobSpecific/components/Brand/ProjectCoordinator";
import ContentWritterForm from "../jobSpecific/components/Brand/ContentWritter";
import FinanceForm from "../jobSpecific/components/Finance&Sales/Finance";
import SalesOperationForm from "../jobSpecific/components/Finance&Sales/SalesOperation";
import AssistantAccountantForm from "../jobSpecific/components/AssAccountant";
import AssistantItForm from "../jobSpecific/components/AssistantIT";
import HumanResourcesForm from "../jobSpecific/components/HumanResources";
import ManagersOrLead from "../jobSpecific/components/Managers";
import {
  AssistantAccountData,
  AssitantItData,
  columnsDataColumns,
  ContentWritterData,
  DesignData,
  developmentsData,
  DigitalData,
  ECommerceData,
  FinanceData,
  HumanResourcesData,
  ItManagerData,
  operationData,
  projectCoordinatorData,
  QaData,
  salesData,
  seoData,
} from "../quarterly/variables/columnsData";
import moment from "moment";
import Digital from "../jobSpecific/components/Digital";

export default function Marketplace() {
  const [data, setData] = React.useState([]);
  const [selectedMember, setSelectedMemeber] = React.useState();
  const [selectManager, setSelectManger] = React.useState("development");
  const [TeamData, setTeamData] = React.useState([]);
  const [communication, setcommunication] = React.useState("0");
  const [behaviour, setbehaviour] = React.useState("0");
  const [ownership, setownership] = React.useState("0");
  const [teamwork, setteamwork] = React.useState("0");
  const [qualitywork, setqualitywork] = React.useState("0");
  const [timelydeliver, settimelydeliver] = React.useState("0");
  const [overall, setoverall] = React.useState(0);
  const [descriptionbox, setDescriptionBox] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const loginData = localStorage.getItem("login");
  const ProfileId = JSON.parse(loginData);
  const [previousCount, setPreviousCount] = useState(1);
  // const ProfileId = useSelector((store) => store.counter.profile);
  const [departmentTable, setDepartment] = React.useState(ProfileId.department);
  const [particularMemberKpi, setParticularMonthKpi] = React.useState([]);
  const [suugest, setSuggest] = useState(true);
  const [value, setValue] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const PreviousMonth =
    months[CurrentMonth == "january" ? 11 : months.indexOf(selectedMonth) - 1];
  console.log(
    "months.indexOf(selectedMonth) - 1",
    months.indexOf(selectedMonth) - 1
  );
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.toLowerCase() !== "naruto") {
      error = "Jeez! You're not a fan 😱";
    }
    return error;
  }
  const form = useRef();

  const parse = (val) => val.replace(/^\$/, "");
  //Team Data
  React.useEffect(() => {
    ProfileId.department == "Director" || ProfileId.department == "HR"
      ? fetch(`${baseURL}api/auth/managers`)
          .then((resp) => resp.json())
          .then((response) => {
            if (ProfileId.role == "Bussiness Unit Head") {
              var newArray = response.filter(function (el) {
                return (
                  el.compliance == false &&
                  (el.department == "brand designer" ||
                    el.department == "brand")
                );
              });

              setData(newArray);
            } else {
              var newArray = response.filter(function (el) {
                return (
                  el.compliance == false &&
                  el.department != "brand designer" &&
                  el.department != "brand"
                );
              });
              setData(newArray);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : ProfileId.superAdmin == true
      ? fetch(`${baseURL}api/auth/team?department=Director&manager=false`)
          .then((resp) => resp.json())
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          })
      : fetch(
          `${baseURL}api/auth/team?department=${departmentTable}&manager=false`
        )
          .then((resp) => resp.json())
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          });
  }, [departmentTable]);
  useEffect(() => {
    //For Team
    fetch(`${baseURL}api/auth/team?department=${selectManager}&manager=false`)
      .then((resp) => resp.json())
      .then((response) => {
        setTeamData(response);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(
      `${baseURL}api/kpi/par_kpi_mon?id=${selectedMember}&month=${PreviousMonth}`
    )
      .then((resp) => resp.json())
      .then((response) => {
        if (response == []) {
          setPreviousCount(2);
        } else {
          // setPreviousCount(1)
          setParticularMonthKpi(response[0]);
        }
      })
      .catch((err) => console.log(err));
  }, [selectManager, selectedMember, selectedMonth]);
  const overallValue =
    Number(communication) +
    Number(behaviour) +
    Number(ownership) +
    Number(teamwork) +
    Number(qualitywork) +
    Number(timelydeliver);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  //For Submit Kpi

  const handleSubmit = (event) => {
    console.log("wordking");
    setLoading(true);
    event.preventDefault();
    const bodyObject = {
      teamId: selectedMember,
      name: "Muhammad",
      managerId: ProfileId._id,
      month: CurrentMonth,
      communication: communication,
      behaviour: behaviour,
      ownership: ownership,
      teamwork: teamwork,
      qualitywork: qualitywork,
      timelydeliver: timelydeliver,
      overall: overallValue / 6,
      descriptionbox: descriptionbox,
    };
    fetch(`${baseURL}api/kpi/addkpi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    })
      .then((resp) => resp.json())
      .then((response) => {
        setLoading(false);
        alert(response.message, "response");
        setcommunication("0");
        setbehaviour("0");
        setownership("0");
        setteamwork("0");
        setqualitywork("0");
        setownership("0");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const developement = ["development", "quality assurance"];
  const design = ["design"];
  const Branddesign = ["brand designer"];
  const design_night = ["design_night"];
  const operation = ["operation"];
  const Brand = ["brand", "seo", "project coordination"];
  const FinanceandSales = [
    "finance",
    // "sales operation",
    // "assistant accountant",
  ];
  const sales = ["sales"];
  const IT = ["assistant it"];
  const HR = ["human resources"];
  const Jay = ["human resources", "Compliance"];
  const nav = useRef();
  const burger = useRef();
  const overlay = useRef();

  return (
    <div className="MainKpi">
      <div className="AddTeamKpi">
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
          {/* Main Fields */}
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="space-between"
            direction={{ base: "column", md: "row" }}
            align={{ base: "start", md: "center" }}
          >
            <Text color={textColor} fontSize="2xl" fontWeight="700">
              Add Team KPI
            </Text>
          </Flex>
          <Box
            // pt={{ base: "180px", md: "180px", xl: "10px" }}
            marginBottom={"85px"}
          >
            {ProfileId.department == "Director" ||
            ProfileId.department == "HR" ||
            ProfileId.department == "ceo" ||
            ProfileId.department == "sirjawad" ? null : (
              <FormControl id="teammember" isRequired top={"-10px"}>
                <FormLabel>Select department</FormLabel>
                <Select
                  onChange={(event) => setDepartment(event.currentTarget.value)}
                >
                  {ProfileId.department == "development" &&
                  ProfileId.manager == true
                    ? developement.map((item) => {
                        return (
                          <option value={item}>
                            {item == "quality assurance"
                              ? "Support and Coordination"
                              : item?.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "design_night" &&
                      ProfileId.manager == true
                    ? design_night.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "design" &&
                      ProfileId.manager == true
                    ? design.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "brand designer" &&
                      ProfileId.manager == true
                    ? Branddesign.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "assistant it" &&
                      ProfileId.manager == true
                    ? IT.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "brand" &&
                      ProfileId.manager == true
                    ? Brand.map((item) => {
                        return (
                          <option value={item}>
                            {item == "project coordination"
                              ? "Support and Coordination"
                              : item == "brand"
                              ? "Brand"
                              : item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "human resources" &&
                      ProfileId.manager == true
                    ? HR.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "sales" &&
                      ProfileId.manager == true
                    ? sales.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : ProfileId.department == "finance" &&
                      ProfileId.manager == true
                    ? FinanceandSales.map((item) => {
                        return (
                          <option value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        );
                      })
                    : null}
                  {/* 
            <option>Sales</option>
            <option>Development</option>
            <option>SEO</option>
            <option>Quality Assurance</option>
            <option>Design</option>
            <option>Operation</option>
            <option>Project Coordination</option>
            <option>Content Writter</option>
            <option>Finance</option>
            <option>Sales Operation</option>
            <option>Assistant Accountant</option>
            <option>Managers</option>
            <option>Assistant IT</option>
            <option>Human Resources</option> */}
                </Select>
              </FormControl>
            )}
            {departmentTable == "sales" ? (
              <SalesForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "development" ? (
              <DevelopmentForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "seo" ? (
              <SEOForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : // : departmentTable == "brand" ? (
            //   <SEOForm
            //     depart={departmentTable}
            //     setSelectedMemeber1={setSelectedMemeber}
            //   />
            // )
            departmentTable == "design" ||
              departmentTable == "design_night" ||
              departmentTable == "brand designer" ? (
              <DesignForm
                setSelectedMemeber1={setSelectedMemeber}
                depart={
                  ProfileId.department == "design_night"
                    ? ProfileId.department
                    : ProfileId.department == "brand designer"
                    ? ProfileId.department
                    : departmentTable
                }
                setValue={setValue}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "quality assurance" ? (
              <QualityAForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "operations" ? (
              <OperationForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "project coordination" ? (
              <ProjectCoordinateForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "content writter" ? (
              <ContentWritterForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "finance" ? (
              // <FinanceForm
              //   depart={departmentTable}
              //   setSelectedMemeber1={setSelectedMemeber}
              // />
              <AssistantAccountantForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "sales operation" ? (
              <SalesOperationForm
                depart={departmentTable}
                setSelectedMonth={setSelectedMonth}
                setSelectedMemeber1={setSelectedMemeber}
              />
            ) : departmentTable == "assistant accountant" ? (
              <AssistantAccountantForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : // : departmentTable == "managers" ? (
            //   <ITManagerForm depart={departmentTable} />
            // )
            departmentTable == "brand" ? (
              <Digital
                depart={departmentTable}
                setValue={setValue}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "assistant it" ? (
              <AssistantItForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "human resources" ? (
              <HumanResourcesForm
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "Director" ? (
              <ManagersOrLead
                setValue={setValue}
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "sirjawad" ? (
              <ManagersOrLead
                setValue={setValue}
                appliance={true}
                depart={departmentTable}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : departmentTable == "ceo" ? (
              <OperationForm
                depart={"Director"}
                setSelectedMemeber1={setSelectedMemeber}
                setSelectedMonth={setSelectedMonth}
              />
            ) : null}
          </Box>
        </Box>
      </div>
      <div className="navBar">
        {particularMemberKpi == [] || particularMemberKpi == undefined ? (
          <h1>No Previous Month Perfomance</h1>
        ) : (
          <div ref={overlay} className="mainNav">
            <Grid gap={{ base: "20px", xl: "20px" }}>
              <div className="suggestBox">
                <p
                  onClick={() => setSuggest(!suugest)}
                  style={{
                    backgroundColor: "black",
                    // width: "125px",
                    padding: "15px",
                    // margin: "5px",
                    right: "40px",
                    display: "flex",
                    alignSelf: "flex-end",
                    position: "absolute",
                    zIndex: 1,
                    cursor: "pointer",
                    top: "-1px",
                    fontWeight: "bold",
                    fontSize: "15px",
                    borderRadius: "25px",
                  }}
                >
                  Suggest Kpi {"  "}
                  <Icon w="38px" h="20px" as={MdArrowBackIos} color={"white"} />
                </p>
              </div>
              {suugest == true ? (
                <Card mb={{ base: "0px", lg: "20px" }} align="center">
                  <Box
                    bg={`url(${"https://wallpaperaccess.com/full/2213566.jpg"})`}
                    bgSize="cover"
                    borderRadius="16px"
                    h="170px"
                    w="100%"
                  />
                  <Avatar
                    mx="auto"
                    src={avatar}
                    h="87px"
                    w="87px"
                    mt="-43px"
                    border="4px solid"
                  />
                  {/* <Text
                  color={textColorPrimary}
                  fontWeight="bold"
                  fontSize="xl"
                  mt="10px"
                >
                  {"name"}
                </Text>
                <Text color={textColorSecondary} fontSize="sm">
                  {"job"}
                </Text> */}
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="xl"
                    mt="10px"
                  >
                    {`${
                      PreviousMonth?.charAt(0)?.toUpperCase() +
                      PreviousMonth?.slice(1)
                    } Score`}
                  </Text>
                  <Text
                    color={textColorPrimary}
                    fontSize="2xl"
                    fontWeight="bold"
                  >
                    {Number(
                      (particularMemberKpi?.communication +
                        particularMemberKpi?.behaviour +
                        particularMemberKpi?.ownership +
                        particularMemberKpi?.teamwork +
                        particularMemberKpi?.qualitywork +
                        particularMemberKpi?.timelydeliver +
                        particularMemberKpi?.overall +
                        particularMemberKpi?.goals) /
                        8
                    ).toFixed(2)}
                  </Text>
                  <Flex
                    w="max-content"
                    mx="auto"
                    width={"100%"}
                    mt="26px"
                    flexWrap={"wrap"}
                    justifyContent={"center"}
                  >
                    <div style={{ width: "150px", margin: "15px" }}>
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.communication}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[1].Header
                          : departmentTable == "development"
                          ? developmentsData[1].Header
                          : departmentTable == "seo"
                          ? seoData[1].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[1].Header
                            : DesignData[1].Header
                          : departmentTable == "quality assurance"
                          ? QaData[1].Header
                          : departmentTable == "operations"
                          ? operationData[1].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[1].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[1].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[1].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[1].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[1].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[1].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[1].Header
                            :  value =="63ab608218db7a3fe9b6504c" ?  DigitalData[1].Header: ItManagerData[1].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[1].Header
                            : ItManagerData[1].Header
                          : departmentTable == "ceo"
                          ? operationData[1].Header
                          : null}
                      </Text>
                    </div>
                    <div style={{ width: "150px", margin: "15px" }}>
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.behaviour}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[2].Header
                          : departmentTable == "development"
                          ? developmentsData[2].Header
                          : departmentTable == "seo"
                          ? seoData[2].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[2].Header
                            : DesignData[2].Header
                          : departmentTable == "quality assurance"
                          ? QaData[2].Header
                          : departmentTable == "operations"
                          ? operationData[2].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[2].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[2].Header
                            : ItManagerData[2].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[2].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[2].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[2].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[2].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[2].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[2].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[2].Header: ItManagerData[2].Header
                          : departmentTable == "ceo"
                          ? operationData[2].Header
                          : null}
                      </Text>
                    </div>
                    <div style={{ width: "150px", margin: "15px" }}>
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.ownership}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[3].Header
                          : departmentTable == "development"
                          ? developmentsData[3].Header
                          : departmentTable == "seo"
                          ? seoData[3].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[3].Header
                            : DesignData[3].Header
                          : departmentTable == "quality assurance"
                          ? QaData[3].Header
                          : departmentTable == "operations"
                          ? operationData[3].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[3].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[3].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[3].Header
                            : ItManagerData[3].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[3].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[3].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[3].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[3].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[3].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[3].Header: ItManagerData[3].Header
                          : departmentTable == "ceo"
                          ? operationData[3].Header
                          : null}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "150px",
                        marginTop: "25px",
                        margin: "15px",
                      }}
                    >
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.teamwork}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[4].Header
                          : departmentTable == "development"
                          ? developmentsData[4].Header
                          : departmentTable == "seo"
                          ? seoData[4].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[4].Header
                            : DesignData[4].Header
                          : departmentTable == "quality assurance"
                          ? QaData[4].Header
                          : departmentTable == "operations"
                          ? operationData[4].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[4].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[4].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[4].Header
                            : ItManagerData[4].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[4].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[4].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[4].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[4].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[4].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[4].Header: ItManagerData[4].Header
                          : departmentTable == "ceo"
                          ? operationData[4].Header
                          : null}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "150px",
                        marginTop: "25px",
                        margin: "15px",
                      }}
                    >
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.timelydeliver}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[5].Header
                          : departmentTable == "development"
                          ? developmentsData[5].Header
                          : departmentTable == "seo"
                          ? seoData[5].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[5].Header
                            : DesignData[5].Header
                          : departmentTable == "quality assurance"
                          ? QaData[5].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[5].Header
                            : ItManagerData[5].Header
                          : departmentTable == "operations"
                          ? operationData[5].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[5].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[5].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[5].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[5].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[5].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[5].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[5].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[5].Header: ItManagerData[5].Header
                          : departmentTable == "ceo"
                          ? operationData[5].Header
                          : null}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "150px",
                        marginTop: "25px",
                        margin: "15px",
                      }}
                    >
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {particularMemberKpi?.qualitywork}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[6].Header
                          : departmentTable == "development"
                          ? developmentsData[6].Header
                          : departmentTable == "seo"
                          ? seoData[6].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[6].Header
                            : DesignData[6].Header
                          : departmentTable == "quality assurance"
                          ? QaData[6].Header
                          : departmentTable == "operations"
                          ? operationData[6].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[6].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[6].Header
                            : ItManagerData[6].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[6].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[6].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[6].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[6].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[6].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[6].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[6].Header: ItManagerData[6].Header
                          : departmentTable == "ceo"
                          ? operationData[6].Header
                          : null}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "150px",
                        marginTop: "25px",
                        margin: "15px",
                      }}
                    >
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {Number(particularMemberKpi?.overall).toFixed(2)}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[7].Header
                          : departmentTable == "development"
                          ? developmentsData[7].Header
                          : departmentTable == "seo"
                          ? seoData[7].Header
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[7].Header
                            : DesignData[7].Header
                          : departmentTable == "quality assurance"
                          ? QaData[7].Header
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[7].Header
                            : ItManagerData[7].Header
                          : departmentTable == "operations"
                          ? operationData[7].Header
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[7].Header
                          : departmentTable == "content writter"
                          ? ContentWritterData[7].Header
                          : departmentTable == "finance"
                          ? AssistantAccountData[7].Header
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[7].Header
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[7].Header
                          : departmentTable == "human resources"
                          ? HumanResourcesData[7].Header
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[7].Header
                            : value =="63ab608218db7a3fe9b6504c" ?  DigitalData[7].Header: ItManagerData[7].Header
                          : departmentTable == "ceo"
                          ? operationData[7].Header
                          : null}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "150px",
                        marginTop: "25px",
                        margin: "15px",
                      }}
                    >
                      <Text
                        color={textColorPrimary}
                        fontSize="1xl"
                        fontWeight="700"
                      >
                        {Number(particularMemberKpi?.goals).toFixed(2)}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="13px"
                        fontWeight="400"
                      >
                        {departmentTable == "sales"
                          ? salesData[8].Header.slice(0, 19)
                          : departmentTable == "development"
                          ? developmentsData[8].Header.slice(0, 19)
                          : departmentTable == "seo"
                          ? seoData[8].Header.slice(0, 19)
                          : departmentTable == "design" ||
                            departmentTable == "design_night" ||
                            departmentTable == "brand designer"
                          ? value == "63ab608218db7a3fe9b6504c"
                            ? DigitalData[8].Header.slice(0, 19)
                            : DesignData[8].Header.slice(0, 19)
                          : departmentTable == "quality assurance"
                          ? QaData[8].Header.slice(0, 19)
                          : departmentTable == "sirjawad"
                          ? value == "6347026485d8e33605a9df27"
                            ? HumanResourcesData[8].Header
                            : ItManagerData[8].Header
                          : departmentTable == "operations"
                          ? operationData[8].Header.slice(0, 19)
                          : departmentTable == "project coordination"
                          ? projectCoordinatorData[8].Header.slice(0, 19)
                          : departmentTable == "content writter"
                          ? ContentWritterData[8].Header.slice(0, 19)
                          : departmentTable == "finance"
                          ? AssistantAccountData[8].Header.slice(0, 19)
                          : departmentTable == "assistant accountant"
                          ? AssistantAccountData[8].Header.slice(0, 19)
                          : // : departmentTable == "managers" ? (
                          //   <ITManagerForm depart={departmentTable} />
                          // )
                          departmentTable == "assistant it"
                          ? AssitantItData[8].Header.slice(0, 19)
                          : departmentTable == "human resources"
                          ? HumanResourcesData[8].Header.slice(0, 19)
                          : departmentTable == "Director"
                          ? value == "63c6c392c071aaa8f6b3d9d9"
                            ? ECommerceData[8].Header
                            :  value =="63ab608218db7a3fe9b6504c" ?  DigitalData[8].Header:ItManagerData[8].Header.slice(0, 19)
                          : departmentTable == "ceo"
                          ? operationData[8].Header.slice(0, 19)
                          : null}
                      </Text>
                    </div>
                  </Flex>
                </Card>
              ) : null}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
